export const naviConfig = {
  'SUPERADMIN': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-view-dashboard-outline',
      url: '/eld-dashboard',
      lang_id: 'menu.eld_dashboard'
    },
    {
      icon: 'mdi-account-group',
      'icon-alt': 'mdi-chevron-down',
      text: 'User management',
      url: '/users',
      children: [
        {
          icon: 'mdi-account-multiple',
          url: '/users',
          lang_id: 'other.users'
        },
        {
          icon: 'mdi-account-key',
          url: '/roles',
          lang_id: 'other.roles'
        },
        {
          icon: 'mdi-android',
          url: '/manage-app-users',
          lang_id: 'other.manage_app_users'
        }
      ]
    },
    {
      icon: 'mdi-view-dashboard-edit',
      'icon-alt': 'mdi-chevron-down',
      text: 'Content',
      ur: '/boards',
      children: [
        {
          icon: 'mdi-developer-board',
          url: '/boards',
          lang_id: 'other.boards'
        },
        {
          icon: 'mdi-domain',
          url: '/companies',
          lang_id: 'other.companies'
        },
        {
          icon: 'mdi-call-split',
          url: '/load-types',
          lang_id: 'other.loadTypes'
        },
        {
          icon: 'mdi-account-details-outline',
          url: '/menage-fm',
          lang_id: 'other.menageFm'
        }
      ]
    },
    {
      icon: 'mdi-headset',
      'icon-alt': 'mdi-chevron-down',
      text: 'ELD',
      url: '/shifts',
      children: [
        {
          icon: 'mdi-account-clock',
          url: '/shifts',
          lang_id: 'other.shifts'
        },
        {
          icon: 'mdi-alert-octagon',
          url: '/dot-inspections',
          lang_id: 'other.dot'
        },
        {
          icon: 'mdi-format-color-fill',
          url: '/eld-types',
          lang_id: 'other.eld_types'
        }
        // {icon: 'mdi-card-account-details', url: '/eld-drivers', lang_id: 'other.eld_drivers'},
        // {icon: 'mdi-truck-fast', url: '/eld-trucks', lang_id: 'other.eld_trucks'},
        // {icon: 'mdi-domain', url: '/eld-companies', lang_id: 'other.eld_companies'},
      ]
    },
    {
      icon: 'mdi-truck-trailer',
      'icon-alt': 'mdi-chevron-down',
      text: 'Trailer',
      url: '/trailers',
      children: [
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers',
          lang_id: 'other.trailers'
        },
        {
          icon: 'mdi-truck-trailer',
          url: '/available-trailers',
          lang_id: 'other.availableTrailers'
        },
        {
          icon: 'mdi-history',
          url: '/available-trailers?isHistory=true',
          lang_id: 'other.availableTrailersHistory'
        },
        {
          icon: 'mdi-history',
          url: '/trailers-inactive-drivers',
          lang_id: 'other.availableTrailersInactive'
        },
        {
          url: '/trailers/marks',
          lang_id: 'other.trailerMarks'
        },
        {
          url: '/trailers/types',
          lang_id: 'other.trailerTypes'
        }
      ]
    },
    {
      icon: 'mdi-truck',
      'icon-alt': 'mdi-chevron-down',
      text: 'Trucks',
      url: '/trucks/all',
      children: [
        {
          icon: 'mdi-truck-outline',
          url: '/trucks/all',
          lang_id: 'other.trucks'
        },
        {
          icon: 'mdi-truck',
          url: '/available-trucks',
          lang_id: 'other.availableTrucks'
        },
        {
          icon: 'mdi-truck-flatbed',
          url: '/truck-make',
          lang_id: 'other.truckMake'
        },
        {
          icon: 'mdi-truck-flatbed',
          url: '/truck-model',
          lang_id: 'other.truckModel'
        },
        {
          icon: 'mdi-chart-box',
          url: '/statistics',
          lang_id: 'other.charts.trucksStatistics'
        }
      ]
    },
    {
      icon: 'mdi-truck',
      'icon-alt': 'mdi-chevron-down',
      text: 'Pre Book',
      url: '/pre-book/post-loads',
      children: [
        {
          icon: 'mdi-domain',
          url: '/pre-book/companies',
          lang_id: 'other.companies'
        },
        {
          icon: 'mdi-truck-cargo-container',
          url: '/pre-book/post-loads',
          lang_id: 'other.prebookLoads'
        },
        {
          icon: 'mdi-truck-check',
          url: '/pre-book/post-trucks',
          lang_id: 'other.prebookTrucks'
        },
        {
          icon: 'mdi-truck-check',
          url: '/pre-book/posted-trucks',
          lang_id: 'other.prebookTrucksPosted'
        },
      ]
    },

    // {
    //   icon: 'mdi-account-injury',
    //   'icon-alt': 'mdi-chevron-down',
    //   text: 'Accounting',
    //   url: '/accounting',
    //   children: [
    //     { icon: 'mdi-bank', url: '/banks', lang_id: 'other.accounting.banks' },
    //     { icon: 'mdi-bank', url: '/banks?isDealer=true', lang_id: 'other.accounting.dealers' },
    //     { icon: 'mdi-ammunition', url: '/accounting/units', lang_id: 'other.accounting.units' },
    //     { icon: 'mdi-ammunition', url: '/accounting/units?paidWithCash=true', lang_id: 'other.accounting.paidWithCash' },
    //     { icon: 'mdi-domain', url: '/accounting/lease', lang_id: 'other.accounting.lease' },
    //   ]
    // },
    {
      icon: 'mdi-cash',
      'icon-alt': 'mdi-chevron-down',
      text: 'Leasing',
      url: '/lease/companies',
      children: [
        {url: '/lease/companies', lang_id: 'other.leaseCompanies'},
        {url: '/lease/units', lang_id: 'other.leaseUnits'},
      ]
    },
    {
      icon: 'mdi-card-account-details',
      url: '/drivers',
      lang_id: 'other.drivers'
    },
    {
      icon: 'mdi-card-account-details-outline',
      url: '/drivers/deleted',
      lang_id: 'other.drivers_deleted'
    },
    {
      icon: 'mdi-monitor-dashboard',
      url: '/fm-board',
      text: 'FM board',
      children: [
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG',
          lang_id: 'other.fmBoardBG'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG1',
          lang_id: 'other.fmBoardBG1'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG2',
          lang_id: 'other.fmBoardBG2'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG3',
          lang_id: 'other.fmBoardBG3'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS',
          lang_id: 'other.fmBoardNIS'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS1',
          lang_id: 'other.fmBoardNIS1'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS2',
          lang_id: 'other.fmBoardNIS2'
        },
      ]
    },
    {
      icon: 'mdi-phone',
      url: '/contacts',
      lang_id: 'other.contacts'
    },
    {
      icon: 'mdi-android-messages',
      url: '/messages',
      lang_id: 'other.messages'
    },
    {
      icon: 'mdi-domain',
      url: '/owners/third',
      lang_id: 'other.thirdParty'
    },
    {
      icon: 'mdi-account-tie',
      url: '/owners/owner',
      lang_id: 'other.owners'
    },
    {
      icon: 'mdi-hand-clap',
      url: '/dealerships',
      lang_id: 'other.dealerships'
    },
    {
      icon: 'mdi-alpha-y-box',
      url: '/yards',
      lang_id: 'other.yards'
    },
    // {icon: 'mdi-math-log', url: '/logs', lang_id: 'other.logs'},
    {
      icon: 'mdi-group',
      url: '/recruitment',
      lang_id: 'other.recruitment.tab'
    },
    {
      icon: 'mdi-store',
      url: '/shop',
      lang_id: 'menu.shop'
    },
    {
      icon: 'mdi-book-account',
      url: '/permit-books',
      lang_id: 'other.permit_books'
    },
    {
      icon: 'mdi-file-document-multiple-outline',
      url: '/driver-documents',
      lang_id: 'other.driver_documents'
    }
  ],
  'ADMIN': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-view-dashboard-edit',
      'icon-alt': 'mdi-chevron-down',
      text: 'Content',
      children: [
        {
          icon: 'mdi-developer-board',
          url: '/boards',
          lang_id: 'other.boards'
        },
        {
          icon: 'mdi-call-split',
          url: '/load-types',
          lang_id: 'other.loadTypes'
        }
      ]
    },
    {
      icon: 'mdi-truck-trailer',
      'icon-alt': 'mdi-chevron-down',
      text: 'Trailer',
      url: '/trailers',
      children: [
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers',
          lang_id: 'other.trailers'
        },
        {
          icon: 'mdi-truck-trailer',
          url: '/available-trailers',
          lang_id: 'other.availableTrailers'
        },
        {
          icon: 'mdi-history',
          url: '/available-trailers?isHistory=true',
          lang_id: 'other.availableTrailersHistory'
        },
        {
          icon: 'mdi-history',
          url: '/trailers-inactive-drivers',
          lang_id: 'other.availableTrailersInactive'
        },
        {
          url: '/trailers/marks',
          lang_id: 'other.trailerMarks'
        },
        {
          url: '/trailers/types',
          lang_id: 'other.trailerTypes'
        }
      ]
    },
    {
      icon: 'mdi-truck',
      'icon-alt': 'mdi-chevron-down',
      text: 'Pre Book',
      url: '/pre-book/post-loads',
      children: [
        {
          icon: 'mdi-domain',
          url: '/pre-book/companies',
          lang_id: 'other.companies'
        },
        {
          icon: 'mdi-truck-cargo-container',
          url: '/pre-book/post-loads',
          lang_id: 'other.prebookLoads'
        },
        {
          icon: 'mdi-truck-check',
          url: '/pre-book/post-trucks',
          lang_id: 'other.prebookTrucks'
        },
      ]
    },
    {
      icon: 'mdi-card-account-details',
      url: '/drivers',
      lang_id: 'other.drivers'
    },
    {
      icon: 'mdi-card-account-details-outline',
      url: '/drivers/deleted',
      lang_id: 'other.drivers_deleted'
    },
    {
      icon: 'mdi-phone',
      url: '/contacts',
      lang_id: 'other.contacts'
    },
    {
      icon: 'mdi-android-messages',
      url: '/messages',
      lang_id: 'other.messages'
    },
    {
      icon: 'mdi-domain',
      url: '/owners/third',
      lang_id: 'other.thirdParty'
    },
    {
      icon: 'mdi-account-tie',
      url: '/owners/owner',
      lang_id: 'other.owners'
    },
    {
      icon: 'mdi-hand-clap',
      url: '/dealerships',
      lang_id: 'other.dealerships'
    },
    {
      icon: 'mdi-alpha-y-box',
      url: '/yards',
      lang_id: 'other.yards'
    },
    {
      icon: 'mdi-chart-box',
      url: '/statistics',
      lang_id: 'other.charts.trucksStatistics'
    },
    {
      icon: 'mdi-group',
      url: '/recruitment',
      lang_id: 'other.recruitment.tab'
    },
    {
      icon: 'mdi-book-account',
      url: '/permit-books',
      lang_id: 'other.permit_books'
    },
    {
      icon: 'mdi-book-account',
      url: '/permit-books',
      lang_id: 'other.permit_books'
    },
    {
      icon: 'mdi-file-document-multiple-outline',
      url: '/driver-documents',
      lang_id: 'other.driver_documents'
    }
  ],
  'DISPATCHER': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-view-dashboard-edit',
      'icon-alt': 'mdi-chevron-down',
      text: 'Content',
      children: [
        {
          icon: 'mdi-call-split',
          url: '/load-types',
          lang_id: 'other.loadTypes'
        }
      ]
    },
    {
      icon: 'mdi-card-account-details',
      url: '/drivers',
      lang_id: 'other.drivers'
    },
    {
      icon: 'mdi-store',
      url: '/shop',
      lang_id: 'menu.shop'
    },
    {
      icon: 'mdi-book-account',
      url: '/permit-books',
      lang_id: 'other.permit_books'
    }
    // {icon: 'mdi-truck', url: '/drivers/deleted', lang_id: 'other.drivers_deleted'},
    // {icon: 'mdi-math-log', url: '/logs', lang_id: 'other.logs'}
  ],
  'COMMENTS': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    }
  ],
  'PM_DEPARTMENT': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    }
  ],
  'FLEET_MANAGER': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-monitor-dashboard',
      url: '/fm-board',
      text: 'FM board',
      children: [
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG',
          lang_id: 'other.fmBoardBG'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG1',
          lang_id: 'other.fmBoardBG1'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG2',
          lang_id: 'other.fmBoardBG2'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG3',
          lang_id: 'other.fmBoardBG3'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS',
          lang_id: 'other.fmBoardNIS'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS1',
          lang_id: 'other.fmBoardNIS1'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS2',
          lang_id: 'other.fmBoardNIS2'
        },
      ]
    },
    {
      icon: 'mdi-store',
      url: '/shop',
      lang_id: 'Shop'
    },
  ],
  'PAYROLL': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-card-account-details-outline',
      url: '/drivers/deleted',
      lang_id: 'other.drivers_deleted'
    }
  ],
  'SAFETY': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-card-account-details',
      url: '/drivers',
      lang_id: 'other.drivers'
    },
    {
      icon: 'mdi-view-dashboard-edit',
      'icon-alt': 'mdi-chevron-down',
      text: 'Content',
      ur: '/boards',
      children: [
        {
          icon: 'mdi-developer-board',
          url: '/boards',
          lang_id: 'other.boards'
        },
        {
          icon: 'mdi-domain',
          url: '/companies',
          lang_id: 'other.companies'
        },
        {
          icon: 'mdi-call-split',
          url: '/load-types',
          lang_id: 'other.loadTypes'
        },
        {
          icon: 'mdi-account-details-outline',
          url: '/menage-fm',
          lang_id: 'other.menageFm'
        }
      ]
    },
    {
      icon: 'mdi-card-account-details-outline',
      url: '/drivers/deleted',
      lang_id: 'other.drivers_deleted'
    },
    {
      icon: 'mdi-book-account',
      url: '/permit-books',
      lang_id: 'other.permit_books'
    }
  ],
  'READ': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    }
  ],
  'EFS': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-card-account-details-outline',
      url: '/drivers/deleted',
      lang_id: 'other.drivers_deleted'
    }
  ],
  'ELD_ADMIN': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-view-dashboard-outline',
      url: '/eld-dashboard',
      lang_id: 'menu.eld_dashboard'
    },
    {
      icon: 'mdi-headset',
      'icon-alt': 'mdi-chevron-down',
      text: 'ELD',
      url: '/shifts',
      children: [
        {
          icon: 'mdi-account-clock',
          url: '/shifts',
          lang_id: 'other.shifts'
        },
        {
          icon: 'mdi-alert-octagon',
          url: '/dot-inspections',
          lang_id: 'other.dot'
        },
        {
          icon: 'mdi-format-color-fill',
          url: '/eld-types',
          lang_id: 'other.eld_types'
        }
        // {icon: 'mdi-card-account-details', url: '/eld-drivers', lang_id: 'other.eld_drivers'},
        // {icon: 'mdi-truck-fast', url: '/eld-trucks', lang_id: 'other.eld_trucks'},
        // {icon: 'mdi-domain', url: '/eld-companies', lang_id: 'other.eld_companies'}
      ]
    },
    {
      icon: 'mdi-store',
      url: '/shop',
      lang_id: 'menu.shop'
    },
  ],
  'SHIFT_LEADER': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-view-dashboard-outline',
      url: '/eld-dashboard',
      lang_id: 'menu.eld_dashboard'
    },
    {
      icon: 'mdi-headset',
      'icon-alt': 'mdi-chevron-down',
      text: 'ELD',
      url: '/shifts',
      children: [
        {
          icon: 'mdi-account-clock',
          url: '/shifts',
          lang_id: 'other.shifts'
        },
        {
          icon: 'mdi-alert-octagon',
          url: '/dot-inspections',
          lang_id: 'other.dot'
        }
      ]
    }
  ],
  'ELD': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-view-dashboard-outline',
      url: '/eld-dashboard',
      lang_id: 'menu.eld_dashboard'
    },
    {
      icon: 'mdi-store',
      url: '/shop',
      lang_id: 'menu.shop'
    },
  ],
  'ACCOUNTING': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-card-account-details-outline',
      url: '/drivers/deleted',
      lang_id: 'other.drivers_deleted'
    },
    {
      icon: 'mdi-file-document-multiple-outline',
      url: '/driver-documents',
      lang_id: 'other.driver_documents'
    }
  ],
  'TRAILER_DEPARTMENT': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-truck-trailer',
      'icon-alt': 'mdi-chevron-down',
      text: 'Trailer',
      url: '/trailers',
      children: [
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers',
          lang_id: 'other.trailers'
        },
        {
          icon: 'mdi-truck-trailer',
          url: '/available-trailers',
          lang_id: 'other.availableTrailers'
        },
        {
          icon: 'mdi-history',
          url: '/available-trailers?isHistory=true',
          lang_id: 'other.availableTrailersHistory'
        },
        {
          icon: 'mdi-history',
          url: '/trailers-inactive-drivers',
          lang_id: 'other.availableTrailersInactive'
        },
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers/marks',
          lang_id: 'other.trailerMarks'
        },
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers/types',
          lang_id: 'other.trailerTypes'
        }
      ]
    },
    {
      icon: 'mdi-domain',
      url: '/owners/third',
      lang_id: 'other.thirdParty'
    },
    {
      icon: 'mdi-account-tie',
      url: '/owners/owner',
      lang_id: 'other.owners'
    },
    {
      icon: 'mdi-hand-clap',
      url: '/dealerships',
      lang_id: 'other.dealerships'
    },
    {
      icon: 'mdi-alpha-y-box',
      url: '/yards',
      lang_id: 'other.yards'
    },
    {
      icon: 'mdi-store',
      url: '/shop',
      lang_id: 'menu.shop'
    },
  ],
  'TRUCK_DEPARTMENT': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-truck-trailer',
      'icon-alt': 'mdi-chevron-down',
      text: 'Trailer',
      url: '/trailers',
      children: [
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers',
          lang_id: 'other.trailers'
        }
      ]
    },
    {
      icon: 'mdi-truck',
      'icon-alt': 'mdi-chevron-down',
      text: 'Trucks',
      url: '/trucks/all',
      children: [
        {
          icon: 'mdi-truck-outline',
          url: '/trucks/all',
          lang_id: 'other.trucks'
        },
        {
          icon: 'mdi-truck',
          url: '/available-trucks',
          lang_id: 'other.availableTrucks'
        },
        {
          icon: 'mdi-truck-flatbed',
          url: '/truck-make',
          lang_id: 'other.truckMake'
        },
        {
          icon: 'mdi-truck-flatbed',
          url: '/truck-model',
          lang_id: 'other.truckModel'
        },
        {
          icon: 'mdi-chart-box',
          url: '/statistics',
          lang_id: 'other.charts.trucksStatistics'
        }
      ]
    },
    {
      icon: 'mdi-domain',
      url: '/owners/third',
      lang_id: 'other.thirdParty'
    },
    {
      icon: 'mdi-account-tie',
      url: '/owners/owner',
      lang_id: 'other.owners'
    },
    {
      icon: 'mdi-hand-clap',
      url: '/dealerships',
      lang_id: 'other.dealerships'
    },
    {
      icon: 'mdi-alpha-y-box',
      url: '/yards',
      lang_id: 'other.yards'
    }
  ],
  'CLAIMS': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    }
  ],
  'RECRUITING': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-card-account-details-outline',
      url: '/drivers/deleted',
      lang_id: 'other.drivers_deleted'
    },
    {
      icon: 'mdi-group',
      url: '/recruitment',
      lang_id: 'other.recruitment.tab'
    }
  ],
  'RECRUITER_ADMIN': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-card-account-details-outline',
      url: '/drivers/deleted',
      lang_id: 'other.drivers_deleted'
    },
    {
      icon: 'mdi-group',
      url: '/recruitment',
      lang_id: 'other.recruitment.tab'
    }
  ],
  'RECRUITER_VIEW': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-group',
      url: '/recruitment',
      lang_id: 'other.recruitment.tab'
    }
  ],
  'BROKER': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-truck',
      'icon-alt': 'mdi-chevron-down',
      text: 'Pre Book',
      children: [
        {
          icon: 'mdi-domain',
          url: '/pre-book/companies',
          lang_id: 'other.companies'
        },
        {
          icon: 'mdi-truck-cargo-container',
          url: '/pre-book/post-loads',
          lang_id: 'other.prebookLoads'
        },
        {
          icon: 'mdi-truck-check',
          url: '/pre-book/post-trucks',
          lang_id: 'other.prebookTrucks'
        },
        {
          icon: 'mdi-truck-check',
          url: '/pre-book/posted-trucks',
          lang_id: 'other.prebookTrucksPosted'
        },
      ]
    },
  ],
  'ASSET': [
    {
      icon: 'mdi-home-account',
      url: '/',
      lang_id: 'menu.home'
    },
    {
      icon: 'mdi-view-dashboard',
      url: '/dashboard',
      lang_id: 'menu.dashboard'
    },
    {
      icon: 'mdi-monitor-dashboard',
      url: '/fm-board',
      text: 'FM board',
      children: [
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG',
          lang_id: 'other.fmBoardBG'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG1',
          lang_id: 'other.fmBoardBG1'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG2',
          lang_id: 'other.fmBoardBG2'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=BG3',
          lang_id: 'other.fmBoardBG3'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS',
          lang_id: 'other.fmBoardNIS'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS1',
          lang_id: 'other.fmBoardNIS1'
        },
        {
          icon: 'mdi-monitor-dashboard',
          url: '/fm-board?city=NIS2',
          lang_id: 'other.fmBoardNIS2'
        },
      ]
    },
    {
      icon: 'mdi-truck-trailer',
      'icon-alt': 'mdi-chevron-down',
      text: 'Trailer',
      url: '/trailers',
      children: [
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers',
          lang_id: 'other.trailers'
        },
        {
          icon: 'mdi-truck-trailer',
          url: '/available-trailers',
          lang_id: 'other.availableTrailers'
        },
        {
          icon: 'mdi-history',
          url: '/available-trailers?isHistory=true',
          lang_id: 'other.availableTrailersHistory'
        },
        {
          icon: 'mdi-history',
          url: '/trailers-inactive-drivers',
          lang_id: 'other.availableTrailersInactive'
        },
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers/marks',
          lang_id: 'other.trailerMarks'
        },
        {
          icon: 'mdi-truck-trailer',
          url: '/trailers/types',
          lang_id: 'other.trailerTypes'
        }
      ]
    },
    {
      icon: 'mdi-truck',
      'icon-alt': 'mdi-chevron-down',
      text: 'Trucks',
      url: '/trucks/all',
      children: [
        {
          icon: 'mdi-truck-outline',
          url: '/trucks/all',
          lang_id: 'other.trucks'
        },
        {
          icon: 'mdi-truck',
          url: '/available-trucks',
          lang_id: 'other.availableTrucks'
        },
        {
          icon: 'mdi-truck-flatbed',
          url: '/truck-make',
          lang_id: 'other.truckMake'
        },
        {
          icon: 'mdi-truck-flatbed',
          url: '/truck-model',
          lang_id: 'other.truckModel'
        },
        {
          icon: 'mdi-chart-box',
          url: '/statistics',
          lang_id: 'other.charts.trucksStatistics'
        }
      ]
    },
    {
      icon: 'mdi-domain',
      url: '/owners/third',
      lang_id: 'other.thirdParty'
    },
    {
      icon: 'mdi-account-tie',
      url: '/owners/owner',
      lang_id: 'other.owners'
    },
    {
      icon: 'mdi-hand-clap',
      url: '/dealerships',
      lang_id: 'other.dealerships'
    },
    {
      icon: 'mdi-alpha-y-box',
      url: '/yards',
      lang_id: 'other.yards'
    },
    {
      icon: 'mdi-store',
      url: '/shop',
      lang_id: 'menu.shop'
    },
  ]
}

export const permissionLinksMap = {
  'MENAGE_FM_ALL': {icon: 'mdi-account-details-outline', url: '/menage-fm', lang_id: 'other.menageFm'},
  'AVAILABLE_TRUCKS_VIEW': {icon: 'mdi-truck', url: '/available-trucks', lang_id: 'other.availableTrucks'},
  'TRUCK_VIEW': {icon: 'mdi-truck', url: '/trucks/all', lang_id: 'other.trucks'},
  'DRIVERS_VIEW': {
    icon: 'mdi-card-account-details',
    url: '/drivers',
    lang_id: 'other.drivers'
  },
  'DRIVERS_DELETED_VIEW': {
    icon: 'mdi-card-account-details-outline',
    url: '/drivers/deleted',
    lang_id: 'other.drivers_deleted'
  },
  'DISPATCHER_READ': {
    icon: 'mdi-view-dashboard',
    url: '/dashboard',
    lang_id: 'menu.dashboard'
  },
  'DISPATCHER_ALL': {
    icon: 'mdi-view-dashboard',
    url: '/dashboard',
    lang_id: 'menu.dashboard'
  },
  'ACCOUNTING_VIEW_ALL': {
    icon: 'mdi-calculator-variant',
    'icon-alt': 'mdi-chevron-down',
    text: 'Accounting',
    url: '/accounting',
    children: [
      { icon: 'mdi-bank', url: '/banks', lang_id: 'other.accounting.banks' },
      { icon: 'mdi-bank', url: '/banks?isDealer=true', lang_id: 'other.accounting.dealers' },
      { icon: 'mdi-ammunition', url: '/accounting/all-units', lang_id: 'other.accounting.allUnits' },
      { icon: 'mdi-ammunition', url: '/accounting/units', lang_id: 'other.accounting.units' },
      { icon: 'mdi-ammunition', url: '/accounting/units?paidWithCash=true', lang_id: 'other.accounting.paidWithCash' },
      { icon: 'mdi-domain', url: '/accounting/lease', lang_id: 'other.accounting.lease' },
      { icon: 'mdi-ammunition', url: '/accounting/units-history', lang_id: 'other.accounting.unitsHistory' },
    ]
  },
  'ACCOUNTING_VIEW_UNITS': {
    icon: 'mdi-calculator-variant',
    'icon-alt': 'mdi-chevron-down',
    text: 'Accounting',
    url: '/accounting',
    children: [
      { icon: 'mdi-ammunition', url: '/accounting/units', lang_id: 'other.accounting.units' },
      { icon: 'mdi-ammunition', url: '/accounting/units?paidWithCash=true', lang_id: 'other.accounting.paidWithCash' },
    ]
  },
  'PREBOOK_LOAD_VIEW': {
    icon: 'mdi-truck',
    'icon-alt': 'mdi-chevron-down',
    text: 'Pre Book',
    url: '/pre-book/post-loads',
    children: [
      {
        icon: 'mdi-truck-cargo-container',
        url: '/pre-book/post-loads',
        lang_id: 'other.prebookLoads'
      }
    ]
  },
  'PREBOOK_POST_TRUCKS_VIEW': {
    icon: 'mdi-truck-check',
    url: '/pre-book/post-trucks',
    lang_id: 'other.prebookTrucks'
  },
  'PREBOOK_POSTED_TRUCKS_VIEW': {
    icon: 'mdi-truck-check',
    url: '/pre-book/posted-trucks',
    lang_id: 'other.prebookTrucksPosted'
  },
  'MANAGE_APP_USERS': {
    icon: 'mdi-android',
    url: '/manage-app-users',
    lang_id: 'other.manage_app_users'
  },
  'RECRUIT_APP_USERS': {
    icon: 'mdi-android',
    url: '/manage-app-users',
    lang_id: 'other.manage_app_users'
  },
  'PERMITBOOK_VIEW': {
    icon: 'mdi-book-account',
    url: '/permit-books',
    lang_id: 'other.permit_books'
  },
  'DRIVER_DOCUMENTS_VIEW': {
    icon: 'mdi-file-document-multiple-outline',
    url: '/driver-documents',
    lang_id: 'other.driver_documents'
  }
}
