<template>
  <div class="managefm-wrapper">
    <form @submit.prevent="onSubmitSearch">
      <v-row>
        <v-col cols="2" class="d-flex align-center">
          <v-text-field
            class="pr-2"
            prepend-inner-icon="mdi-magnify"
            v-model="searchItems"
            clearable
            dense
            solo
            :light="true"
            height="28"
            label="Search FM or truck number"
            single-line
            hide-details="auto"
            persistent-hint
            :hint="foundedDrivers ? `Found ${foundedDrivers} drivers` : 'Search FM or truck number'"
            @input="onSearch"
            @click:clear="onClearSearchFm"
          ></v-text-field>
        </v-col>
        <v-col cols="1" class="mt-2">
          <v-btn type="submit" :disabled="!searchItems || (searchItems && searchItems.length <= 2)" color="primary" small :loading="loading">Search</v-btn>
        </v-col>
        <v-col cols="2" class="mt-1">
          <v-switch class="mt-0" v-model="showInactive" @change="getAllData" label="Show inactive" hide-details small></v-switch>
        </v-col>
        <v-divider />
        <v-col cols="2">
          <v-text-field
            class="pr-2"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            clearable
            dense
            solo
            :light="true"
            height="28"
            label="search drivers"
            single-line
            hide-details
            @input="onSearch"
            @click:clear="onClearSearch"
          ></v-text-field>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col cols="10" class="fm-container">
        <v-row class="cards pl-3 pr-3" v-if="fms.length">
          <fm-card v-for="(user, index) in fms" :key="user._id" @setActiveFm="setActiveFm" @removeDriver="removeDriver" @addDriver="onAddDriver" :user="user" :showInactive="showInactive" :isActive="activeFm === index" :search="searchItems"></fm-card>
        </v-row>
        <v-row class="pl-3 pr-3" v-else>
          <v-col cols="12">
            <p class="text-center subtitle-1" v-if="(!searchItems || !searchItems.length) && loading">Loading ...</p>
            <p class="text-center subtitle-1" v-else-if="searchItems && searchItems.length && !loading">Not found, try something else</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <div class="drivers-no-fm mt-2">
          <draggable
            v-model="driversNoFm"
            :sort="false"
            :group="{ name: 'driversNoFm', pull: true, put: false }"
          >
            <v-chip
              small
              class="mr-2 mb-1 my-chip"
              v-for="driver in filteredArray"
              :data-id="driver._id"
              :key="driver._id"
              label
              :color="!driver.is_active ? 'warning' : ''"
              :title="!driver.is_active ? 'Inactive driver' : ''"
            >
              <span><strong>{{driver.truck}}</strong> - {{driver.name}}</span>
              <span>{{driver.board ? driver.board.name : ''}}</span>
              
            </v-chip>
          </draggable>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import draggable from 'vuedraggable'
import driverService from '../../services/DriverService'
import FmCard from './FmCard'

export default {
  name: 'MenageFm',
  components: {
    draggable,
    FmCard
  },
  data() {
    return {
      fms: [],
      search: '',
      searchItems: null,
      activeFm: null,
      driversNoFm: [],
      filteredArray: [],
      showInactive: false,
      foundedDrivers: 0
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    fmsGroupedDrivers () {
      return this.fms.map(fm => {
        let tmpDrivers = {}
        let newFm = {...fm}
        let count = fm.drivers.length
        for (let driver of fm.drivers) {
          if (driver.board) {
            if (tmpDrivers[driver.board.name]) {
              tmpDrivers[driver.board.name].push(driver)
            } else {
              tmpDrivers[driver.board.name] = []
              tmpDrivers[driver.board.name].push(driver)
            }
          }
        }
        // const tmpDriversOrdered = Object.keys(tmpDrivers).sort().reduce(
        //   (obj, key) => { 
        //     obj[key] = tmpDrivers[key]
        //     return obj
        //   }, 
        //   {}
        // )

        if (fm.inactiveDrivers && fm.inactiveDrivers.length) {
          newFm.countInactive = fm.inactiveDrivers.length
          tmpDrivers['Inactive'] = fm.inactiveDrivers
        }

        newFm.drivers = tmpDrivers
        newFm.count = count
        return newFm
      })
    }
  },
  methods: {
    onSearch () {
      if (this.search && this.search.length >= 3) {
        let searchTerm = this.search.toLowerCase()
        this.filteredArray = this.driversNoFm.filter(driver => {
          return driver.name.toLowerCase().includes(searchTerm) || driver.truck.toLowerCase().includes(searchTerm)
        })
      } else {
        this.filteredArray = this.driversNoFm
      }
    },
    onClearSearch () {
      this.search = ''
      this.filteredArray = this.driversNoFm
    },
    onAddDriver (data) {
      const {event, fmUser} = data
      let driverId = event.item.dataset.id
      // provera da li negde vec postoji vozac kod nekog drugog fm-a
      // for (let user of this.fms) {
      //   if (user.drivers.findIndex(driver => driver._id === driverId) !== -1) {
      //     let confVal = confirm(`This driver already has an assigned fleet manager: ${user.name}, Do you want to continue?`)
      //     if (!confVal) {
      //       return
      //     }
      //   }
      // }
      
      let fmUserFormated = {...fmUser}
      delete fmUserFormated.drivers
      delete fmUserFormated.countActive
      this.$store.dispatch('common/setLoading', true)
      driverService.updateDriverFm(driverId, fmUserFormated).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          let tmpDriver = {
            _id: res.data.driver._id,
            name: res.data.driver.name,
            truck: res.data.driver.truck,
            board: res.data.driver.board,
            is_active: res.data.driver.is_active
          }
          let index = this.fms.findIndex(fm => fm._id === fmUser._id)
          let boardName = tmpDriver.board.name
          if (!tmpDriver.is_active) {
            boardName = 'Inactive'
          } else {
            this.fms[index].countActive += 1
          }
          if (this.fms[index].drivers[boardName]) {
            this.fms[index].drivers[boardName].push(tmpDriver)
          } else {
            this.fms[index].drivers[boardName] = [tmpDriver]
          }

          let driverIndex = this.driversNoFm.findIndex(driver => driver._id === tmpDriver._id)
          this.driversNoFm.splice(driverIndex, 1)
          this.onClearSearch()
        }
      })
    },
    removeDriver (data) {
      let confVal = confirm('Are you sure you want to delete the fleet manager for this driver?')
      if (!confVal) {
        return
      }
      const {driverId, board, fmUser} = data

      this.$store.dispatch('common/setLoading', true)
      driverService.updateDriverFm(driverId, null).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          let tmpDriver = {
            _id: res.data.driver._id,
            name: res.data.driver.name,
            truck: res.data.driver.truck,
            board: res.data.driver.board,
            is_active: res.data.driver.is_active
          }
          let indexFm = this.fms.findIndex(fm => fm._id === fmUser._id)
          let indexDriver = this.fms[indexFm].drivers[board] && this.fms[indexFm].drivers[board].findIndex(driver => driver._id === driverId)
          console.log('indexFm', 'indexDriver', indexFm, indexDriver, board)
          if (indexDriver != undefined && indexDriver !== -1) {
            if (this.fms[indexFm].drivers[board][indexDriver].is_active) {
              this.fms[indexFm].countActive = this.fms[indexFm].countActive - 1
            }
            this.fms[indexFm].drivers[board].splice(indexDriver, 1)
            if (!this.fms[indexFm].drivers[board].length) {
              delete this.fms[indexFm].drivers[board]
            }
            this.driversNoFm.push(tmpDriver)
          }
        }
      })
    },
    setActiveFm (id) {
      let index = this.fms.findIndex(fm => fm._id === id)
      if (this.activeFm !== index) {
        this.activeFm = index
      } else {
        this.activeFm = null
      }
    },
    onSubmitSearch() {
      this.getAllData()
    },
    onClearSearchFm() {
      this.searchItems = null
      this.getAllData()
    },
    getAllData() {
      this.$store.dispatch('common/setLoading', true)
      driverService.getDriversGroupedByFm({search: this.searchItems, showInactive: this.showInactive}).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          let fmDrivers = res.data.fmDrivers
          this.fms = fmDrivers
          this.foundedDrivers = res.data.searchTruckResult

          if (!this.searchItems) {
            driverService.getDriversHasNoFm().then(res => {
              this.driversNoFm = res.data.drivers
              this.filteredArray = res.data.drivers
            })
          }
        }
      })
    }
  },
  mounted() {
    this.getAllData()
  }
}
</script>

<style lang="scss">
.managefm-wrapper {
  width: 100%;
}

.fm-container {
  max-height: 850px;
  overflow-y: scroll;
  margin-top: 18px;
}

.drivers-no-fm {
  max-height: 795px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 8px;
  .v-chip {
    cursor: move;
  }
}

.my-chip {
  font-size: 11px !important;
  height: 32px !important;
  line-height: 16px !important;
  .v-chip__content {
    align-items: flex-start;
    display: flex;
    height: 100%;
    max-width: 100%;
    flex-direction: column;
  }
}
</style>