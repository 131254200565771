<template>
  <div class="AvailableView">
    <div class="AvailableView__container">
      <div v-if="yards" class="AvailableView__yards">
        <available-table
          v-for="y in yards"
          :key="y._id"
          :item="y"
          :transferOptions="transferOptions"
          type="yard"
        />
      </div>

      <div v-if="dealerships" class="AvailableView__dealerships">
        <available-table
          v-for="d in dealerships"
          :key="d._id"
          :item="d"
          :transferOptions="transferOptions"
          type="dealership"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AvailableTable from './AvailableTable.vue'
import trucksService from '../../services/TruckService'
import { mapGetters } from 'vuex'

const GET_YARDS = 'yards/getAllYards'
const GET_DEALERSHIPS = 'dealerships/getAllDealerships'

const time = (date) => new Date(date).getTime()

export default {
  name: 'AvailableView',

  components: { AvailableTable },

  data() {
    return {
      yards: null,
      dealerships: null,
    }
  },

  computed: {
    ...mapGetters('auth', ['permissions']),
    
    transferOptions() {
      let options = []

      if (this.yards)
        options = [
          ...options,
          ...this.yards.map((y) => ({ ...y, type: 'yard' })),
        ]

      if (this.dealerships)
        options = [
          ...options,
          ...this.dealerships.map((y) => ({ ...y, type: 'dealership' })),
        ]

      return options
    },

    trucksLink() {
      return this.permissions['AVAILABLE_TRUCKS_ALL']
    },
  },

  async created() {
    await this.fetchYards()
    await this.fetchDealerships()
  },

  mounted() {
    this.$socket.client.emit('joinToTruck')
    this.$store.dispatch('common/getAllCompanies')
  },

  beforeDestroy() {
    this.$socket.client.emit('leave_truck')
  },

  sockets: {
    connect() {
      this.$socket.client.emit('joinToTruck')
    },

    truckMoved(data) {
      const { from, to, truck } = data
      if (from) this.removeTruck(from, truck)
      if (to) this.addTruck(to, truck)
    },
    clearList(data) {
      const { type, id } = data
      const items = [...this[`${type}s`]]
      const typeI = items.findIndex((t) => t._id === id)
      const newArray = items[typeI].rows.map((item) => {
        item.aIsOnTheList = false
        return item
      })
      items[typeI].rows = newArray
      this[`${type}s`] = items
    },
  },

  methods: {
    removeTruck({ type, id }, { _id }) {
      const items = [...this[type]]
      const typeI = items.findIndex((t) => t._id === id)
      const newArray = items[typeI].rows.filter((i) => i._id !== _id)
      items[typeI].rows = newArray
      this[type] = items
    },

    async addTruck({ type, id }, truck) {
      const items = [...this[type]]
      const typeI = items.findIndex((t) => t._id === id)
      const newArray = [...items[typeI].rows, truck]
      items[typeI].rows = this.sortTrucks(
        newArray,
        this[type][typeI].isSpecial
      )
      this[type] = items
    },

    initialSort(trucks) {
      return trucks.sort((a, b) => Number(b.number) - Number(a.number))
    },

    sortSpecial(trucks) {
      return trucks.sort((a, b) => time(a.aModified) - time(b.aModified))
    },

    sortRegular(trucks) {
      return trucks.sort((a, b) => a.aStatus.order - b.aStatus.order)
    },

    sortTrucks(trucks, isSpecial) {
      const sorted = this.initialSort(trucks)
      return isSpecial ? this.sortSpecial(sorted) : this.sortRegular(sorted)
    },

    async fetchYards() {
      try {
        const results = await this.$store.dispatch(GET_YARDS, 'TRUCK')

        if (Array.isArray(results)) {
          const yards = []

          for (const y of results) {
            const rows = await this.fetchTableItems(
              y._id,
              'yards',
              y.isSpecial
            )
            yards.push({ ...y, rows })
          }

          this.yards = yards
        }
      } catch (error) {
        console.error('AvailableView fetchYards()', error)
      }
    },

    async fetchDealerships() {
      try {
        const results = await this.$store.dispatch(GET_DEALERSHIPS, 'TRUCK')

        if (Array.isArray(results)) {
          const dealerships = []

          for (const d of results) {
            const rows = await this.fetchTableItems(
              d._id,
              'dealerships',
              d.isSpecial
            )
            dealerships.push({ ...d, rows })
          }

          this.dealerships = dealerships
        }
      } catch (error) {
        console.error('AvailableView fetchDealerships()', error)
      }
    },

    async fetchTableItems(id, type, isSpecial) {
      const fn = type === 'yards' ? 'trucksByYard' : 'trucksByDealership'

      try {
        const { data } = await trucksService[fn](id, isSpecial)
        return Array.isArray(data.docs)
          ? this.sortTrucks(data.docs, isSpecial)
          : []
      } catch (error) {
        console.error('AvailableTable fetchTableItems()', error)
        return []
      }
    },
  },
}
</script>

<style lang="scss">
.AvailableView {
  overflow: scroll;
  padding-bottom: 20px;

  &__container {
    width: max-content;
    padding: 1px;
    display: flex;

    & > *:not(:last-child) {
      margin-right: 32px;
    }
  }

  &__yards,
  &__dealerships {
    & > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
