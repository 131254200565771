<script>
import { Bar } from 'vue-chartjs'

export default {
  name: 'PillarChart',

  extends: Bar,

  props: {
    chartData: Object,
    title: String,
    stacked: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    console.log('this.chartData', JSON.stringify(this.chartData))

    const maxYValue = Math.max(...this.chartData.datasets[0].data)
    const roundedMax = Math.round((maxYValue + (maxYValue * 0.1)) / 100) * 100

    this.renderChart(this.chartData, {
      legend: false,
      title: { display: true, text: this.title || 'Chart' },
      ...(this.stacked
        ? {
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                min: 0,
                max: roundedMax
              }
            }]
          }
        }
        : {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                min: 0,
                max: roundedMax
              }
            }]
          },
          responsive: true,
          maintainAspectRatio: true,
          tooltips: {
            enabled: true
          },
          hover: {
            animationDuration: 1
          },
          animation: {
            duration: 1,
            onComplete: function () {
              const chartInstance = this.chart
              const ctx = chartInstance.ctx
              ctx.textAlign = 'center'
              ctx.fillStyle = 'rgba(0, 0, 0, 1)'
              ctx.textBaseline = 'bottom'

              // Loop through each data in the datasets
              this.data.datasets.forEach(function (dataset, i) {
                let meta = chartInstance.controller.getDatasetMeta(i)
                meta.data.forEach(function (bar, index) {
                  let data = dataset.data[index]
                  ctx.fillText(data, bar._model.x, bar._model.y - 2)
                })
              })
            }
          }
        }),
    })
  },
}
</script>
