<template>
  <div class='map-wrapper'>
    <div id='map-container'></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import EventBus from '../../eventbus/EventBus'

let map = null
let activePopup = null
export default {
  data() {
    return {
      originalGeoJSON: null,
      padding: { top: 20, right: 20, bottom: 50, left: 20 },
      activePopup: null,
    }
  },
  mounted() {
    EventBus.$on('shopCardHovered', id => {
      this.handleCardHover(id)
    })
    EventBus.$on('shopCardNotHovered', this.handleCardNotHover)
    this.initMap()
    window.addEventListener('resize', () => {
      map.resize()
    })
  },
  methods: {
    getInitialZoom() {
      if (window.innerWidth > 1920) {
        return 4.7
      } else if (window.innerWidth > 1512) {
        return 4.0
      } else if(window.innerWidth>1390){
        return 3.7
      }
      else {
        return 2.5
      }
    },
    initMap() {
      mapboxgl.accessToken =
        'pk.eyJ1IjoibmVtYW5qYTk0NyIsImEiOiJjbHU5eDUxYXowZW80Mm9xazc0N3owNTU1In0.kvnFr9ZoUrH0pxuxxYqtAA'

      map = new mapboxgl.Map({
        container: 'map-container',
        style:'mapbox://styles/mapbox/streets-v12',
        center: [-98.3, 37.0],
        zoom: this.getInitialZoom(),
        minZoom: this.getInitialZoom(),
      })

      let nav = new mapboxgl.NavigationControl()
      map.addControl(nav, 'top-right')

      map.on('style.load', async () => {
        this.padding.top = 84
        this.padding.left = 20

        map.easeTo(
          {
            padding: this.padding,
            duration: 500,
          },
          { isProgramatic: true }
        )

        try {
          if (!this.originalGeoJSON) {
            const response = await fetch(
              `${process.env.VUE_APP_SHOPS_API_URL}/shop/map/geojson`
            )
            this.originalGeoJSON = await response.json()
          }

          map.addSource('shops', {
            type: 'geojson',
            data: this.originalGeoJSON,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
          })
        } catch (err) {
          console.error('Error loading the GeoJSON data:', err)
        }

        this.addMapLayers()
        this.clickEventsCluster()
        this.clickEventsPin()
        this.createPopup()
      })

      map.on('moveend', async (event) => {
        if (!event.isProgramatic) {
          const bounds = map.getBounds()
          this.$emit('map-view-changed', {
            north: bounds.getNorth(),
            south: bounds.getSouth(),
            east: bounds.getEast(),
            west: bounds.getWest(),
            zoomLevel: map.getZoom(),
          })
        }
      })
    },
    addMapLayers() {
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'shops',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#0C4130',
            30,
            '#146E51',
            100,
            '#21AD80',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            30,
            30,
            100,
            40,
          ],
        },
      })

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'shops',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        },
      })

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'shops',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'case',
            ['==', ['get', 'label'], 'GOLD'], '#B99042', 
            ['==', ['get', 'label'], 'PLATINUM'], '#B4C5E4', 
            ['==', ['get', 'label'], 'SILVER'], '#717B8E',
            ['==', ['get', 'status'], 'BLACKLISTED'], '#8A1829',
            '#0C4130'
          ],
          'circle-radius': 10,
          'circle-stroke-width': 1.5,
          'circle-stroke-color': '#0C4130',
        },
      })
    },
    generatePopUpHtml(properties) {
      const popUpHtml = `
        <div class='d-flex align-center justify-space-between'>
            ${this.generatePopUpRating(properties.averageRating)}
            ${this.generatePopUpLabel(properties)}
        </div>
        <h3 class='map-popup pt-2 text-h6 font-weight-bold text-primary text-truncate'>${properties.name}<h3>
        <h2 class='map-popup pb-4 text-subtitle-1 font-weight-bold text-truncate'>${properties.franchise}<h2>
        <h2 class='map-popup text-subtitle-2 font-weight-bold'>${properties.address}<h2>
      `
      return popUpHtml
    },
    generatePopUpRating(averageRating){
      if(averageRating==null){
        averageRating = 0
      }
      let starsHtml = '<div class="d-flex align-center">'
      const fullStars = Math.floor(parseFloat(averageRating))
      const hasHalfStar = parseFloat(averageRating) % 1 !== 0

      for (let i = 0; i < fullStars; i++) {
        starsHtml += '<span class="mdi mdi-star text-h6 amber--text"></span>'
      }

      if (hasHalfStar) {
        starsHtml += '<span class="mdi mdi-star-half-full text-h6 amber--text"></span>'
      }

      const emptyStars = 5 - Math.ceil(parseFloat(averageRating))
      for (let i = 0; i < emptyStars; i++) {
        starsHtml += '<span class="mdi mdi-star-outline text-h6 amber--text"></span>'
      }
      starsHtml += `<span class="ml-1 text-subtitle-1 font-weight-bold">${averageRating}</span></div>`
      return starsHtml
    },
    generatePopUpLabel(props){
      if(props.status!=null){
        return `<p class="text-subtitle-2 font-weight-bold px-4 py-1 ${props.status.toLowerCase()} d-inline-block rounded-pill white--text">${props.status}</p>`
      }
      else if(props.label!=null && props.label!=''){
        return `<p class="text-subtitle-2 font-weight-bold px-4 py-1 ${props.label.toLowerCase()} d-inline-block rounded-pill white--text">${props.label}</p>`
      }
      else{
        return '<p class="text-subtitle-2 font-weight-bold px-4 py-1 basic d-inline-block rounded-pill white--text">BASIC</p>'
      }
    },
    createPopup() {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'map-popup',
        offset: 12,
      })

      map.on('mouseenter', 'unclustered-point', (e) => {
        map.getCanvas().style.cursor = 'pointer'

        let coordinates = e.features[0].geometry.coordinates.slice()
        const properties = e.features[0].properties

        // Popup screen edge
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        popup
          .setLngLat(coordinates)
          .setHTML(this.generatePopUpHtml(properties))
          .setMaxWidth('300px')
          .addTo(map)
      })

      map.on('mouseleave', 'unclustered-point', () => {
        map.getCanvas().style.cursor = ''
        popup.remove()
      })
    },
    handleCardHover(shopId) {
      if (activePopup) {
        activePopup.remove()
      }
      activePopup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'map-popup',
        offset: 12
      })

      const features = map.querySourceFeatures('shops', {
        sourceLayer: 'unclustered-point',
        filter: ['==', ['get', 'id'], shopId]
      })

      if (features.length > 0) {
        const feature = features[0]
        const coordinates = feature.geometry.coordinates
        const properties = feature.properties

        activePopup
          .setLngLat(coordinates)
          .setHTML(this.generatePopUpHtml(properties))
          .setMaxWidth('300px')
          .addTo(map)
      }
    },
    handleCardNotHover() {
      if(activePopup) {
        activePopup.remove()
        activePopup = null
      }
    },
    clickEventsCluster() {
      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })
        const clusterId = features[0].properties.cluster_id
        map
          .getSource('shops')
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            })
          })
      })

      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = ''
      })
    },

    clickEventsPin() {
      map.on('click', 'unclustered-point', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['unclustered-point'],
        })

        if (!features.length) {
          return
        }

        const clickedFeature = features[0]

        this.$router.push({
          name: 'ShopDetails',
          params: {
            shopId: clickedFeature.properties.id,
          },
        })
      })
    },

    fitMapToPins(geoJSONFeatures) {
      if (!geoJSONFeatures.length) {
        return
      }

      const bounds = geoJSONFeatures.reduce((b, feature) => {
        return b.extend(feature.geometry.coordinates)
      }, new mapboxgl.LngLatBounds(geoJSONFeatures[0].geometry.coordinates, geoJSONFeatures[0].geometry.coordinates))

      map.fitBounds(bounds, {
        padding:this.padding,
        maxZoom: 15,
        duration: 1000,
      },
      {isProgramatic: true})
    },

    moveMap(position, zoomLvl = 9) {
      map.easeTo(
        {
          center: [position.lng, position.lat],
          zoom: zoomLvl,
          duration: 1000,
          padding: this.padding,
        },
        { isProgramatic: true }
      )
    },
    updateSource(sourceData, fitToPins = true) {
      if (map.getSource('shops')) {
        map.getSource('shops').setData(sourceData)
      } else {
        map.addSource('shops', { type: 'geojson', data: sourceData })
      }

      if (fitToPins) {
        this.fitMapToPins(sourceData.features)
      }
    },
    resetMap(centerMap = true) {
      if (this.originalGeoJSON && map.getSource('shops')) {
        map.getSource('shops').setData(this.originalGeoJSON)
        if (centerMap) {
          this.moveMap({ lat: 37.0, lng: -98.3 }, this.getInitialZoom())
        }
      }
    },

    resizeMap() {
      map.resize()
    },
    // handleCardHover(shopId) {
    //   // Implementation for handleCardHover
    // },
    // handleCardNotHover() {
    //   // Implementation for handleCardNotHover
    // },
  },
}
</script>

<style lang='scss'>
.map-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  #map-container {
    flex: 1;
    height: 100%
  }
}

.map-popup {
  padding-right: 8px;
  z-index: 1000
}
</style>
