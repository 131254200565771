import Api from '@/services/Api'

export default {
  getMessages(data) {
    return Api().get(`/chat/get-messages-by-group/${data.groupId}/${data.offset}/${data.limit}`)
  },
  sendMessages(data) {
    return Api().post('/chat/add-message', data)
  },
  getUserGroups() {
    return Api().get('/chat/get-user-groups')
  }
}
