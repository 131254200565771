import axios from 'axios'
import store from '../store'
import i18n from '../plugins/lang'
import router from '../router'
import { decodeJwtToken } from '../utils/index'

export default () => {
  const token = localStorage.getItem('msAuthKey') ? JSON.parse(localStorage.getItem('msAuthKey')) : null
  let http = axios.create({
    baseURL: process.env.VUE_APP_SHOPS_API_URL,
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  })

  let authHttp = axios.create({
    baseURL: process.env.VUE_APP_SHOPS_API_AUTH_URL,
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  })

  http.interceptors.request.use(async function (config) {
    if (config.headers['Authorization'] && !config.url.includes('/refresh') && !config.url.includes('/auth')) {
      const decodedToken = decodeJwtToken(config.headers['Authorization'])
      const isTokenExpired = Date.now() >= decodedToken.exp * 1000
      const refreshToken = JSON.parse(localStorage.getItem('msRefreshToken'))

      if (isTokenExpired && refreshToken) {
        const response = await authHttp.post('/refresh', {refresh: refreshToken})
        if (response.status === 201 && response.data && response.data.accessToken) {
          const newToken = response.data.accessToken
          localStorage.setItem('msAuthKey', JSON.stringify(newToken))
          config.headers['Authorization'] = `Bearer ${newToken}`
        } else {
          console.log('no refresh token')
        }
      }
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  http.interceptors.response.use(
    response => response,
    error => {
      const { status } = error.response
      store.dispatch('common/setLoading', false)
      if (status === 500) {
        store.dispatch('common/setMessage', 'Doslo je do greske na serveru!')
      } else if (status === 401) {
        if (router.app.$route.name === 'Shop') {
          store.dispatch('auth/logoutLocal')
          router.push('/login')
        }
      } else if (status === 403) {
        store.dispatch('common/setMessage', `Akcija zabranjena: ${error.response.request.responseURL}`)
      } else if (status === 404) {
        store.dispatch('common/setMessage', `Not found error: ${error.response.request.responseURL}`)
      } else if (status === 400) {
        store.dispatch('common/setMessage', error.response.data.message ? i18n.t(error.response.data.message) : 'Bad request error!')
        console.log('error 400', error.response.data.message)
      }
      return Promise.reject(error.response)
    }
  )

  return {http, authHttp}
}
