<template>
  <div class="YearStats">
    <div class="table">
      <div class="table-header">
        <div class="table-row">
          <div class="table-cell text">{{ title }}</div>
          <div class="table-cell number">
            {{ data.totalTrucks }}
          </div>
          <div class="table-cell number">%</div>
        </div>
      </div>
      <div class="table-body">
        <div
          class="table-row"
          v-for="(item, index) in data.trucksByYear"
          :key="index"
        >
          <div class="table-cell">
            <span v-if="item.year"> Ukupan broj <strong>{{ item.year }}</strong> kamiona: </span>
            <span v-else>Bez godine</span>
          </div>
          <div class="table-cell center">{{ item.count }}</div>
          <div class="table-cell center">
            {{ calculatePercentage(item.count, data.totalTrucks) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YearStats',

  props: ['data', 'title'],

  methods: {
    calculatePercentage(value, total) {
      const percentage = (value / total) * 100
      return `${percentage.toFixed(2)}%`
    },
  },
}
</script>

<style lang="scss">
.YearStats {
  overflow-x: auto;

  .table {
    display: inline-table;
    border-collapse: collapse;
    white-space: nowrap;
    width: max-content;
  }

  .table-header {
    display: table-header-group;
  }

  .table-body {
    display: table-row-group;
  }

  .table-row {
    display: table-row;
  }

  .table-cell {
    display: table-cell;
    padding: 2px 5px;
    border: 1px solid #ccc;

    &.center {
      text-align: center;
    }
  }

  .text {
    min-width: 240px;
  }

  .number {
    min-width: 80px;
    text-align: center;
  }
}
</style>
