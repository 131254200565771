<template>
  <div class="users-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.users') }}
        <v-spacer></v-spacer>
        <v-text-field
          id="step-3"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('other.search')"
          single-line
          hide-details
          height="30"
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        height="760"
        :disable-sort="false"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="users"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': search ? [totalItems, totalItems] : [10, 15, 25]
        }"
        :item-class="itemRowBackground"
        @update:options="getUsers"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.roles="{item}" >
            <!-- <span v-if="item.roles.findIndex(role => role.role_id === 1) !== -1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    slot="activator"
                    color="primary"
                    dark
                  >mdi-account</v-icon>
                </template>
                <span>{{item.roles.map(role => role.role_name).join(', ')}}</span>
              </v-tooltip>
            </span>
            <span v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    slot="activator"
                    color="accent"
                    dark
                  >mdi-account-outline</v-icon>
                </template>
                <span>{{item.roles.map(role => role.role_name).join(', ')}}</span>
              </v-tooltip>
            </span> -->
            {{item.roles.map(role => role.role_name).join(', ')}}
        </template>
        <template v-slot:item.is_team_lead="{item}" >
          {{item.is_team_lead ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              id="step-2"
              @click="updatePassword(item._id)"
            >
              mdi-lock-reset
            </v-icon>
            <v-icon
              small
              class="mr-2"
              id="step-2"
              @click="editUser(item._id)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="showOtpTokens(item._id)"
            >
              mdi-key-chain
            </v-icon>
            <v-icon
              small
              @click="deleteUser(item)"
            >
              mdi-delete
            </v-icon>
            <v-tooltip bottom v-if="isSuperAdmin">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  color="error"
                  @click="deleteUserPermanent(item)"
                >
                  mdi-delete-forever
                </v-icon>
              </template>
              <span>Delete this user forever</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ml-2"
                  @click="createLink(item._id)">
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Create invite link</span>
            </v-tooltip>
          </td>
        </template>
        <template v-slot:item.board="{item}">
          {{item.board_id && item.board_id !== null && getBoardById(item.board_id) ? getBoardById(item.board_id) : '/'}}
        </template>
        <template v-slot:item.lastLoginTime="{item}" >
          {{item.lastLoginTime | formatDateObj('MM/DD/YYYY HH:mm')}}
        </template>
      </v-data-table>
      <!-- Add/invite btns -->
      <v-speed-dial
          class="my-speed-dial"
          absolute
          bottom
          direction="right"
          left
          transition="slide-x-transition"
          v-model="fab"
        >
          <template v-slot:activator>
            <v-btn
              color="primary"
              dark
              v-model="fab"
              fab
              id="step-1"
            >
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            small
            color="green"
            @click.stop="dialogInvite = !dialogInvite"
          >
            <v-icon>mdi-link</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            small
            color="indigo"
            @click.stop="dialog = !dialog"
          >
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
      </v-speed-dial>
    </v-card>
    <!-- Dialog for edit user -->
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <form @submit.prevent="onSaveUser" data-vv-scope="add">
          <v-card-title
            class="lighten-4 py-4 title"
          >
            {{userForm._id !== null ? 'Edit user' : 'Add new user'}}
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  :autofocus="true"
                  name="email"
                  v-model="userForm.email"
                  v-validate="'required|email'"
                  :error="errors.has('add.email')"
                  :error-messages="errors.first('add.email')"
                  prepend-icon="mdi-email"
                  placeholder="Email"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                  <v-text-field
                    v-model="userForm.name"
                    v-validate="'required'"
                    :error="errors.has('add.name')"
                    :error-messages="errors.first('add.name')"
                    prepend-icon="mdi-account-circle"
                    name="name"
                    placeholder="Name"
                  ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select :items="roles"
                  :multiple="multiRole"
                  name="role"
                  v-validate="'required'"
                  :error="errors.has('add.role')"
                  :error-messages="errors.first('add.role')"
                  v-model="userForm.roles"
                  prepend-icon="mdi-face"
                  label="Role"
                  item-value="name"
                  item-text="name"
                  hide-details="auto"
                  return-object
                >
                </v-select>
                <div class="role-permissions mt-3 ml-6" v-if="rolePermissions.length">
                  <v-chip
                    class="mr-2 mb-1"
                    small
                    v-for="(perm, index) in rolePermissions"
                    :key="`roleperm-${index}`"
                    label
                  >{{perm}}</v-chip>
                </div>
              </v-flex>
              <v-flex xs12 v-if="userForm.roles && userForm.roles.role_name === 'DISPATCHER'">
                <v-text-field
                  v-model="userForm.phone_number"
                  name="phone_number"
                  prepend-icon="mdi-phone"
                  placeholder="Phone number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="userForm.board_id"
                  name="board"
                  :items="boards"
                  item-text="name"
                  item-value="_id"
                  label="Board"
                  hide-details="auto"
                  :loading="loading"
                  prepend-icon="mdi-developer-board"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 v-if="isFmRoleSelected">
                <v-select
                  v-model="userForm.fm_city"
                  name="fm_city"
                  :items="['BG1', 'BG2', 'BG3', 'NIS1', 'NIS2']"
                  label="FM city"
                  hide-details="auto"
                  :loading="loading"
                  prepend-icon="mdi-home-city"
                ></v-select>
              </v-flex>
              <v-flex xs3 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.ext"
                    prepend-icon="mdi-alpha-e-circle"
                    name="ext"
                    placeholder="Ext"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.ext_second"
                    prepend-icon="mdi-alpha-e-circle-outline"
                    name="ext_second"
                    placeholder="Ext second"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.ext_third"
                    prepend-icon="mdi-alpha-e-circle-outline"
                    name="ext_third"
                    placeholder="Ext third"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.ext_fourth"
                    prepend-icon="mdi-alpha-e-circle-outline"
                    name="ext_fourth"
                    placeholder="Ext fourth"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                  <v-text-field
                    v-model="userForm.order"
                    type="number"
                    prepend-icon="mdi-order-numeric-ascending"
                    name="Order"
                    placeholder="Order"
                  ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="userForm.is_team_lead"
                  label="Team lead"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="userForm.is_active"
                  label="Is active"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  placeholder="Password"
                  :error="errors.has('add.password')"
                  :error-messages="errors.first('add.password')"
                  prepend-icon="mdi-lock"
                  v-model="userForm.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  name="password"
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <permissions-drag v-if="dialog && userForm.roles" :permissions="userForm.user_permissions" :rolePermissions="rolePermissions" @change="onChangePermissions"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialog = false">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{userForm._id !== null ? 'Update' : 'Save'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <!-- Dialog for invite user -->
    <v-dialog v-model="dialogInvite" width="800px">
      <v-card>
        <form @submit.prevent="onInviteUser" data-vv-scope="invite">
          <v-card-title
            class="lighten-4 py-4 title"
          >
            Invite user
          </v-card-title>
          <v-container v-if="userLink">
          {{userLink}}
            <v-btn
                color="primary"
                dark
                @click="dialogInvite=false"
                fab
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-container>
          <v-container v-else grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  :autofocus="true"
                  name="email"
                  v-model="userForm.email"
                  v-validate="'required|email'"
                  :error="errors.has('invite.email')"
                  :error-messages="errors.first('invite.email')"
                  prepend-icon="mdi-email"
                  placeholder="Email"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.name"
                    v-validate="'required'"
                    :error="errors.has('invite.name')"
                    :error-messages="errors.first('invite.name')"
                    prepend-icon="mdi-account-circle"
                    name="name"
                    placeholder="Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="roles"
                  v-validate="'required'"
                  :error="errors.has('invite.role')"
                  :error-messages="errors.first('invite.role')"
                  :multiple="multiRole"
                  v-model="userForm.roles"
                  prepend-icon="mdi-face"
                  label="Role"
                  item-value="name"
                  item-text="name"
                  name="role"
                  hide-details="auto"
                  return-object
                >
                </v-select>
                <div class="role-permissions mt-3 ml-6" v-if="rolePermissions.length">
                  <v-chip
                    class="mr-2 mb-1"
                    small
                    v-for="(perm, index) in rolePermissions"
                    :key="`roleperm-${index}`"
                    label
                  >{{perm}}</v-chip>
                </div>
              </v-flex>
              <v-flex xs12 v-if="userForm.roles && userForm.roles.role_name === 'DISPATCHER'">
                <v-text-field
                  v-model="userForm.phone_number"
                  name="phone_number"
                  placeholder="Phone number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="userForm.board_id"
                  name="board"
                  :items="boards"
                  item-text="name"
                  item-value="_id"
                  label="Board"
                  hide-details="auto"
                  :loading="loading"
                  prepend-icon="mdi-developer-board"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 v-if="isFmRoleSelected">
                <v-select
                  v-model="userForm.fm_city"
                  name="fm_city"
                  :items="['BG', 'NIS']"
                  label="FM city"
                  hide-details="auto"
                  :loading="loading"
                  prepend-icon="mdi-home-city"
                ></v-select>
              </v-flex>
              <v-flex xs3 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.ext"
                    prepend-icon="mdi-alpha-e-circle"
                    name="ext"
                    placeholder="Ext"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.ext_second"
                    prepend-icon="mdi-alpha-e-circle-outline"
                    name="ext_second"
                    placeholder="Ext second"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.ext_third"
                    prepend-icon="mdi-alpha-e-circle-outline"
                    name="ext_third"
                    placeholder="Ext third"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 align-center justify-space-between>
                <v-text-field
                    v-model="userForm.ext_fourth"
                    prepend-icon="mdi-alpha-e-circle-outline"
                    name="ext_fourth"
                    placeholder="Ext fourth"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  v-model="userForm.order"
                  type="number"
                  prepend-icon="mdi-order-numeric-ascending"
                  name="Order"
                  placeholder="Order"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="userForm.is_team_lead"
                  label="Team lead"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="userForm.is_active"
                  label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <permissions-drag v-if="dialogInvite && userForm.roles" :permissions="userForm.user_permissions" :rolePermissions="rolePermissions" @change="onChangePermissions"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialogInvite = false">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">Invite</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="otpDialog" width="1050px">
      <user-devices :user-tokens="userTokens"></user-devices>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
    >
      Deleting recruiters not allowed while they have employees.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-tour name="users" :steps="steps" :callbacks="{onSkip: this.onSkipTour, onFinish: this.onFinishTour}"></v-tour>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import employeesService from '../../services/EmployeesService'
import UserDevices from './UserDevices.vue'
import PermissionsDrag from '../Roles/PermissionsDrag.vue'
import UsersService from '../../services/UsersService'


const STATUSES = [
  'employed',
  'unemployed',
  'blocked',
  'retired'
]

export default {
  name: 'Users',
  components: {
    PermissionsDrag,
    UserDevices
  },
  data: () => ({
    multiRole: process.env.VUE_APP_MULTI_ROLE === 'yes' ? true : false,
    options: {
      search: '',
      page: 1,
      sortBy: ['roles.name'],
      sortDesc: [false],
      itemsPerPage: 15
    },
    snackbar: false,
    boards: [],
    totalItems: 0,
    search: '',
    dialog: false,
    dialogInvite: false,
    showPass: false,
    userForm: {
      _id: null,
      email: '',
      phone_number: '',
      name: '',
      password: '',
      is_active: true,
      is_team_lead: false,
      roles: null,
      board_id: null,
      fm_city: 'BG',
      ext: '',
      ext_second: '',
      ext_third: '',
      ext_fourth: '',
      order: 0,
      user_permissions: []
    },
    userLink: false,
    headers: [
      { text: 'Name', value: 'name',sortable: false },
      { text: 'Board', value: 'board', sortable: false },
      { text: 'Roles', value: 'roles', sortable: false },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Order', value: 'order', sortable: false },
      { text: 'Team lead', value: 'is_team_lead', sortable: false },
      { text: 'Ext', value: 'ext', sortable: false },
      { text: 'Ext second', value: 'ext_second', sortable: false },
      { text: 'Ext third', value: 'ext_third', sortable: false },
      { text: 'Ext fourth', value: 'ext_fourth', sortable: false },
      { text: 'Last login', value: 'lastLoginTime', sortable: false },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    fab: false,
    steps: [
      {
        target: '#step-1',
        content: 'Here you can add or invite a new user to the application',
        params: {
          placement: 'top'
        }
      },
      {
        target: '#step-2',
        content: 'Edit, delete or invite user again',
        params: {
          placement: 'left'
        }
      },
      {
        target: '#step-3',
        content: 'Find users by email',
        params: {
          placement: 'top'
        }
      }
    ],
    userTokens: null,
    otpDialog: false
  }),
  mounted() {
    if (!localStorage.getItem('tour_users')) {
      this.$tours['users'].start()
    }
    this.$store.dispatch('roles/getRoles')
    this.$store.dispatch('boards/getAllBoards').then(res => {
      this.boards = res
    })

  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    editUser (id) {
      const user = this.$store.getters['user/getUserById'](id)
      this.userForm.name = user.name
      this.userForm.email = user.email
      this.userForm.phone_number = user.phone_number
      this.userForm.is_active = user.is_active
      this.userForm.is_team_lead = user.is_team_lead
      this.userForm.board_id = user.board_id
      this.userForm.fm_city = user.fm_city
      this.userForm.ext = user.ext
      this.userForm.ext_second = user.ext_second
      this.userForm.ext_third = user.ext_third
      this.userForm.ext_fourth = user.ext_fourth
      this.userForm.order = user.order ? user.order : 0
      this.userForm._id = user._id
      this.userForm.user_permissions = user.user_permissions ? Object.keys(user.user_permissions) : []
      if (this.multiRole) {
        this.userForm.roles = this.roles.filter(role => user.roles.findIndex(role2 => role2.role_id === role.role_id) !== -1)
      } else {
        let roleIndex = this.roles.findIndex(role => role._id === user.roles[0]._id)
        this.userForm.roles = this.roles[roleIndex]
      }
      this.dialog = true
    },
    updatePassword(id){
      this.$store.dispatch('user/updateUserPassword', id).then(() => {
        this.setMessage('Password successfully updated')
        this.dialog = false
        this.dialogInvite = false
      })
    },
    clearForm () {
      this.userForm = {
        _id: null,
        email: '',
        phone_number: '',
        name: '',
        password: '',
        board_id: null,
        fm_city: 'BG',
        ext: '',
        ext_second: '',
        ext_third: '',
        ext_fourth: '',
        is_active: true,
        is_team_lead: false,
        roles: null,
        order: 0,
        user_permissions: []
      }

      if (this.multiRole) {
        this.userForm.roles = [{}]
      } else {
        this.userForm.roles = {}
      }
    },
    onSaveUser () {
      this.$validator.validateAll('add').then((result) => {
        if (result) {
          const user = this.userForm
          if (!this.multiRole) { 
            user.roles = [user.roles]
          }
          if (user._id === null) {
            this.$store.dispatch('user/addUser', user).then(res => {
              this.setMessage(`User: ${res.email} successfully added`)
              this.dialog = false
            }).catch(err => {
              if (err.data.code === 11000 && err.data.keyValue.email) {
                this.errors.add({scope: 'add', field: 'email', msg: this.$t('EMAIL_UNIQUE_ERROR')})
              }
            })
          } else {
            this.$store.dispatch('user/updateUser', user).then(res => {
              this.setMessage(`User: ${res.email} successfully updated`)
              this.dialog = false
              this.dialogInvite = false
            }).catch(err => {
              if (err.data.code === 11000 && err.data.keyValue.email) {
                this.errors.add({scope: 'add', field: 'email', msg: this.$t('EMAIL_UNIQUE_ERROR')})
              }
            })
          }
        }
      })
    },
    onInviteUser () {
      this.$validator.validateAll('invite').then((result) => {
        if (result) {
          const user = this.userForm
          if (!this.multiRole) { 
            user.roles = [user.roles]
          }
          this.$store.dispatch('user/inviteUser', user).then(res => {
            this.setMessage(`User: ${res.email} successfully invited`)
            this.userLink = false
            this.dialogInvite = false
            this.clearForm()
          }).catch(err => {
            if (err.data.code === 11000) {
              this.errors.add({scope: 'invite', field: 'email', msg: this.$t('EMAIL_UNIQUE_ERROR')})
            }
          })
        }
      })
    },

    async isDeleteAllowed(id) {
      try {
        const { data } = await employeesService.getByRecruiter(id, { statuses: STATUSES })
        if (data && Array.isArray(data.docs) && data.docs.length > 0) return false
        else return true
      } catch (error) {
        console.error('Users isDeleteAllowed()', error)
        return false
      }
    },

    async deleteUser (user) {
      let deleteAllowed = true
      const isRecruiter = user.roles && user.roles.some(r => r.role_name.includes('RECRUITING'))
      if (isRecruiter) deleteAllowed = await this.isDeleteAllowed(user._id)
      if (!deleteAllowed) return this.snackbar = true

      let confVal = confirm(`Are you sure you want to delete user: ${user.name}?`)
      if (confVal) {
        this.$store.dispatch('user/deleteUser', user._id).then(() => {
          this.setMessage(`User: ${user.email} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err.response)
        })
      }
    },
    async deleteUserPermanent (user) {
      let deleteAllowed = true
      const isRecruiter = user.roles && user.roles.some(r => r.role_name.includes('RECRUITING'))
      if (isRecruiter) deleteAllowed = await this.isDeleteAllowed(user._id)
      if (!deleteAllowed) return this.snackbar = true

      let confVal = confirm(`Are you sure you want to delete user forever: ${user.name}?`)
      if (confVal) {
        const response = await UsersService.deleteUserPermanent(user._id)
        if (response.status === 200) {
          this.setMessage(`User: ${user.email} successfully deleted`)
        }
      }
    },
    getUsers: debounce(function() {
      this.$store.dispatch('user/getUsers', {...this.options, search: this.search }).then(res => {
        this.options.totalItems = res.total
        this.totalItems = res.total
      })
    }, 400),
    searchUsers(search) {
      if(search){
        this.options.itemsPerPage = 100 
        this.options.page = 1
      }else{
        this.options.itemsPerPage = 15
      }
    },
    createLink (id) {
      this.$store.dispatch('user/createNewLink', id).then(res => {
        console.log(res)
        this.userLink = 'User Invited'
        this.dialogInvite = true
      }).catch(err => {
        if (err) {
          console.log('Error on link creation', err)
        }
      })
    },
    getBoardById (id) {
      let board = this.boards.find(board => board._id === id)
      return board ? board.name : false
    },
    onSkipTour () {
      localStorage.setItem('tour_users', 'yes')
    },
    onFinishTour () {
      localStorage.setItem('tour_users', 'yes')
    },
    onChangePermissions(permisions) {
      this.userForm.user_permissions = permisions
    },
    async showOtpTokens(userId) {
      try {
        const res = await this.$store.dispatch('user/getUserTokens', userId)
        this.userTokens = res
        this.otpDialog = true
      } catch (err) {
        console.log('err on get user tokens: ', err)
      }
    },
    itemRowBackground(item) {
      return item.is_deleted ? 'user-background-deleted' : ''
    }
  },
  watch: {
    search: function (newSearch) {
      this.searchUsers(newSearch)
    },
    dialog: function (newVal) {
      if (!newVal) this.clearForm()
      else this.$store.dispatch('roles/getRoles')
    },
    dialogInvite: function (newVal) {
      if (newVal) this.$store.dispatch('roles/getRoles')
    },
    // options: {
    //   handler () {
    //     this.$store.dispatch('user/getUsers', this.options).then(res => {
    //       this.totalItems = res.total
    //     })
    //   },
    //   deep: true,
    // }
  },
  computed: {
    ...mapGetters('user', ['users']),
    ...mapGetters('common', ['loading']),
    ...mapGetters('roles', ['roles']),
    ...mapGetters('auth', ['user', 'isSuperAdmin']),
    boardRequired () {
      return this.userForm.roles !== null && this.userForm.roles.role_name === 'DISPATCHER'
    },
    isFmRoleSelected () {
      return this.userForm.roles && this.userForm.roles.role_name === 'FLEET_MANAGER'
    },
    rolePermissions () {
      return this.userForm.roles && this.userForm.roles.permissions ? Object.keys(this.userForm.roles.permissions).sort() : []
    }
  }
}
</script>

<style lang="scss">
  .users-wrapper {
    width: 100%;

    .v-speed-dial--bottom {
      bottom: 2px;
    }
  }
  // @media only screen and (max-width: 599px) {
  //   .users-wrapper {
  //     margin-top: 50px;
  //   }
  // }
  .user-background-deleted {
    background-color: rgba(239, 154, 154, 0.6);
  }
</style>
