import MsApi from '@/services/MsApi'

export default {
  auth(data) {
    return MsApi().authHttp.post('/auth', data)
  },
  logout() {
    return MsApi().authHttp.post('/logout')
  },
  getShops(data) {
    return MsApi().http.get('/shop', { params: data })
  },
  getShop(shopId) {
    return MsApi().http.get(`/shop/${shopId}`)
  },
  createShop(data) {
    return MsApi().http.post('/shop', data)
  },
  updateShop(data, shopId) {
    return MsApi().http.put(`/shop/${shopId}`, data)
  },
  deleteShop(shopId) {
    return MsApi().http.delete(`/shop/${shopId}`)
  },
  updateShopStatus(data, shopId) {
    return MsApi().http.put(`/shop/status/${shopId}`, data)
  },
  createFranchise(data) {
    return MsApi().http.post('/franchise', data)
  },
  getFranchises() {
    return MsApi().http.get('/franchise?page=1&limit=100')
  },
  getReview(shopId, onlyCompanyReviews) {
    return MsApi().http.get(`/review/${shopId}?onlyCompanyReviews=${onlyCompanyReviews}`)
  },
  writeReview(data) {
    const { recaptchaV2, recaptchaToken, ...updateReviewData } = data
    return MsApi().http.post(`/review?recaptchaToken=${recaptchaToken}&recaptchaV2=${recaptchaV2}`, updateReviewData)
  },
  updateReview(data, reviewId) {
    const { recaptchaV2, recaptchaToken, ...updateReviewData } = data
    return MsApi().http.put(`/review/${reviewId}?recaptchaToken=${recaptchaToken}&recaptchaV2=${recaptchaV2}`, updateReviewData)
  }
}