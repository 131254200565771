import Api from '@/services/Api'

export default {
  getStatististcs() {
    return Api().get('/statististics')
  },
  getYearStats() {
    return Api().get('/statististics/truck-year-stats')
  },
}
