<template>
  <div>
    <span class="claim-type-title">{{selectedClaimTypeName}}</span>
    <div class="table-filters">
      <div class="chackbox-filter">
        <v-switch
          v-model="problemFilter"
          @change="getClaims"
          hide-details
          label="Show only rows with problem"
        ></v-switch>
      </div>
      <div class="select-filter">
        <v-select
            clearable
            v-model="status"
            :items="['OPEN', 'CLOSED']"
            name="status"
            id="status"
            label="Status"
            :light="true"
            height="28"
            single-line
            hide-details
            dense
            solo
            @change="getClaims"
          ></v-select>
      </div>
    </div>
    <v-fab-transition>
      <v-btn
        :disabled="!canAddNew"
        class="claims-add-btn"
        color="pink"
        dark
        bottom
        left
        fab
        small
        @click="formDialogModal = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <div v-if="fieldsByType[type]">
      <div class="table-container-claims">
        <table class="main-table-claims" :class="{dark: dark}">
          <thead>
            <tr>
              <th>#</th>
              <th v-for="item in fieldsByType[type]" :key="item.field">{{item.name}}</th>
              <th>Comments</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!claims.length">
              <td align="center" :colspan="fieldsByType[type].length + 3">No claims for selected filters</td>
            </tr>
            <template v-else>
              <tr v-for="(claim, index) in claims" :class="{problem: claim.problem}" :key="claim._id">
                <td>{{index + 1}}</td>
                <td v-for="item in fieldsByType[type]" :key="`td-${item.field}`">
                  <template v-if="item.type === 'text' || item.type === 'select'">
                    {{claim[item.field] !== '' ? claim[item.field] : '/'}}
                  </template>
                  <template v-else-if="item.type === 'boolean'">
                    {{claim[item.field] ? 'YES' : 'NO'}}
                  </template>
                  <template v-else-if="item.type === 'date'">
                    {{claim[item.field] | formatDate}}
                  </template>
                </td>
                <td align="center">
                  <v-icon
                    :disabled="!canComment"
                    small
                    @click="openComments(claim)"
                  >
                    mdi-comment-text-multiple
                  </v-icon> ({{claim.comments ? claim.comments.length : 0}})
                </td>
                <td>
                  <v-icon
                    small
                    class="mr-2"
                    @click="editClaim(claim)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    :disabled="!canEdit"
                    small
                    class="mr-2"
                    @click="copyClaim(claim)"
                  >
                    mdi-content-copy
                  </v-icon>
                  <v-icon
                    :disabled="!canEdit"
                    small
                    @click="deleteClaim(claim)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <v-pagination
        v-model="page"
        :length="pages"
      ></v-pagination>
    </div>
    <div v-else>
      <p class="subtitle-1" style="text-align: center;">There is no Claims</p>
    </div>
    <v-dialog v-model="formDialogModal" width="800px">
      <v-card>
        <form @submit.prevent="onSubmitClaim" data-vv-scope="claim">
          <v-card-title class="lighten-4 py-4 title">
            {{editId ? 'Edit' : 'Add'}} claim
          </v-card-title>
          <template>
            <v-container grid-list-sm class="pa-4">
              <v-row>
                <v-col
                  v-for="(field, index) in fieldsByType[type]"
                  :key="field.field"
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    :key="`${type}-${field.field}`"
                    v-if="field.type === 'text'"
                    :disabled="isFieldDisabled(field.field)"
                    :maxlength="field.max ? field.max : 150"
                    v-model="field.value"
                    :label="field.name"
                    :name="field.field"
                    :id="field.field"
                    hide-details="auto"
                  ></v-text-field>
                  <v-select
                    v-if="field.type === 'select' && field.field === 'fleet_manager'"
                    :disabled="isFieldDisabled(field.field)"
                    :items="fleetManagers"
                    v-model="field.value"
                    :value="field.value"
                    label="Fleet Manager"
                    item-value="_id"
                    item-text="name"
                    hide-details="auto"
                    clearable
                    return-object></v-select>
                  <v-autocomplete
                    v-if="field.type === 'select' && field.field === 'truck'"
                    v-model="field.value"
                    :disabled="isFieldDisabled(field.field)"
                    :items="trucksListFormated"
                    return-object
                    item-text="truck_id"
                    label="Truck"
                    :search-input.sync="searchDrivers"
                    hide-details="auto"
                    hide-no-data
                    :loading="truckInputLoading"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    :filter="clientFilter"
                    @input="onTruckInput(index)"
                    clearable
                  >
                    <template v-slot:item="{item}">
                      <span :class="{isExternal: item.is_external}">
                        {{`${item.truck_id} - ${item.name}`}}
                        <span v-if="item.is_external">external</span>
                      </span>
                    </template>
                  </v-autocomplete>
                  <v-select
                    v-if="field.type === 'select' && field.options && field.options.length"
                    :disabled="isFieldDisabled(field.field)"
                    :items="field.options"
                    v-model="field.value"
                    :value="field.value"
                    :label="field.label"
                    hide-details="auto"
                    clearable
                  ></v-select>
                  <v-checkbox
                    v-else-if="field.type === 'boolean'"
                    :disabled="isFieldDisabled(field.field)"
                    hide-details="auto"
                    v-model="field.value"
                    :label="field.name"
                    :name="field.field"
                  ></v-checkbox>
                  <v-menu
                    v-else-if="field.type === 'date'"
                    :disabled="isFieldDisabled(field.field)"
                    v-model="field.show"
                    :close-on-content-click="false"
                    :return-value.sync="form.date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="isFieldDisabled(field.field)"
                        v-model="field.value"
                        :label="field.name"
                        :name="field.field"
                        :id="field.field"
                        readonly
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="field.date"
                      no-title
                      color="green lighten-1"
                      @input="closeDatePicker(field)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="onCancel">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{editId ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="copyClaimModal" width="300px">
      <v-card>
        <form @submit.prevent="finsihCopy" data-vv-scope="claim">
          <v-card-title class="lighten-4 py-4 title">
            Copy claim to
          </v-card-title>
          <template>
            <v-container grid-list-sm class="pa-4">
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-select
                    :items="claimTypes"
                    v-model="copyType"
                    :value="copyType"
                    label="Select claim type to copy"
                    item-value="type"
                    item-text="name"
                    hide-details="auto"
                    clearable>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="onCancelCopy">Cancel</v-btn>
            <v-btn color="success" :loading="loading" :disabled="loading" type="submit">Copy</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="commentsModal" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Comments</span>
        </v-card-title>
        <form>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-col cols="12">
                      <v-textarea
                        v-model="commentText"
                        @keydown.enter="handleEnter($event)"
                        ref="comtext"
                        :autofocus="true"
                        name="comment"
                        label="Comment"
                        :hide-details="'auto'"
                        rows="3"
                        outlined
                        counter
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-btn :loading="loading" :disabled="!canComment || commentText === '' || commentText.length > 500" @click="addComment" color="success" small>
                        {{editCommentId === null ? 'Add comment' : 'Edit comment'}}
                      </v-btn>
                      <v-btn @click="cancelCommentEdit" class="ml-3" small v-if="editCommentId">
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="selectedClaim && selectedClaim.comments.length">
                      <Comments @edit="onEditComment" @delete="onDeleteComment" :comments="selectedClaim.comments" :showActions="true" :key="`comments-${selectedClaim.comments.length}`"/>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onCloseCommentsModal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import debounce from 'lodash/debounce'
import callsService from '../../services/CallsService'
import claimsService from '../../services/ClaimsService'
import Comments from '../Common/Comments'

export default {
  components: {
    Comments
  },
  data() {
    return {
      type: null,
      problemFilter: false,
      formDialogModal: false,
      form: {},
      fleetManagers: [],
      trucks: [],
      truckInputLoading: false,
      searchDrivers: '',
      claims: [],
      editId: null,
      page: 1,
      pages: 0,
      copyClaimModal: false,
      claimToCopy: null,
      claimTypes: [],
      copyType: null,
      commentsModal: false,
      commentText: '',
      selectedClaim: null,
      editCommentId: null,
      fieldsByType: {
        'BOBTAIL_INSURANCE': [
          {
            name: 'Truck#',
            field: 'truck',
            type: 'text',
            max: 8,
            value: null
          },
          {
            name: 'Trailer#',
            field: 'trailer',
            type: 'text',
            max: 8,
            value: ''
          },
          {
            name: 'State',
            field: 'state',
            type: 'text',
            value: ''
          },
          {
            name: 'Loss date',
            field: 'loss_date',
            type: 'date',
            show: false,
            value: this.formatDate(new Date().toISOString().substr(0, 10)),
            date: new Date().toISOString().substr(0, 10)
          },
          {
            name: 'Truck claim is open',
            field: 'truck_claim_is_open',
            type: 'boolean',
            value: false
          },
          {
            name: 'Trailer claim is open',
            field: 'trailer_claim_is_open',
            type: 'boolean',
            value: false
          },
          {
            name: 'Claim open date',
            field: 'claim_open_date',
            type: 'date',
            show: false,
            value: this.formatDate(new Date().toISOString().substr(0, 10)),
            date: new Date().toISOString().substr(0, 10)
          },
          {
            name: 'Claim number',
            field: 'claim_number',
            type: 'text',
            value: ''
          },
          {
            name: 'Insurance company',
            field: 'insurance_company',
            type: 'text',
            value: ''
          },
          {
            name: 'Company name',
            field: 'company_name',
            type: 'text',
            value: ''
          },
          {
            name: 'Driver name',
            field: 'driver_name',
            type: 'text',
            value: null
          },
          {
            name: 'Fleet manager',
            field: 'fleet_manager',
            type: 'select',
            value: null
          },
          {
            name: 'Towing',
            field: 'towing',
            type: 'text',
            value: ''
          },
          {
            name: 'Truck price fix',
            field: 'truck_price_fix',
            type: 'text',
            value: ''
          },
          {
            name: 'Truck insurance price fix',
            field: 'truck_insurance_price_fix',
            type: 'text',
            value: ''
          },
          {
            name: 'Truck fixed',
            field: 'truck_fixed',
            type: 'boolean',
            value: false
          },
          {
            name: 'Trailer price fix',
            field: 'trailer_price_fix',
            type: 'text',
            value: ''
          },
          {
            name: 'Trailer insurance price fix',
            field: 'trailer_insurance_price_fix',
            type: 'text',
            value: ''
          },
          {
            name: 'Trailer fixed',
            field: 'trailer_fixed',
            type: 'boolean',
            value: false
          },
          {
            name: 'Towing reimbursement',
            field: 'towing_reimbursement',
            type: 'text',
            value: ''
          },
          {
            name: 'OPEN/CLOSED',
            label: 'open/closed/endorsement',
            field: 'is_open',
            type: 'select',
            options: ['OPEN', 'ENDORSEMENT'],
            value: ''
          },
          {
            name: 'Problem',
            field: 'problem',
            type: 'boolean',
            value: false
          },
        ],
        'CARGO_CLAIMS': [
          {
            name: 'Truck#',
            field: 'truck',
            type: 'text',
            max: 8,
            value: null
          },
          {
            name: 'Trailer#',
            field: 'trailer',
            type: 'text',
            max: 8,
            value: ''
          },
          {
            name: 'State',
            field: 'state',
            type: 'text',
            value: ''
          },
          {
            name: 'Loss date',
            field: 'loss_date',
            type: 'date',
            show: false,
            value: this.formatDate(new Date().toISOString().substr(0, 10)),
            date: new Date().toISOString().substr(0, 10)
          },
          {
            name: 'Claim open date',
            field: 'claim_open_date',
            type: 'date',
            show: false,
            value: this.formatDate(new Date().toISOString().substr(0, 10)),
            date: new Date().toISOString().substr(0, 10)
          },
          {
            name: 'Claim number',
            field: 'claim_number',
            type: 'text',
            value: ''
          },
          {
            name: 'Insurance company',
            field: 'insurance_company',
            type: 'text',
            value: ''
          },
          {
            name: 'Company name',
            field: 'company_name',
            type: 'text',
            value: ''
          },
          {
            name: 'Broker',
            field: 'broker',
            type: 'text',
            value: ''
          },
          {
            name: 'Open Balance',
            field: 'open_balance',
            type: 'text',
            value: ''
          },
          {
            name: 'Driver name',
            field: 'driver_name',
            type: 'text',
            value: null
          },
          {
            name: 'Towing cargo reimbursement',
            field: 'towing_cargo_reimbursement',
            type: 'text',
            value: ''
          },
          {
            name: 'Salvage value',
            field: 'salvage_value',
            type: 'text',
            value: ''
          },
          {
            name: 'Claim amount',
            field: 'claim_amount',
            type: 'text',
            value: ''
          },
          {
            name: 'OPEN/CLOSED',
            label: 'open/closed/endorsement',
            field: 'is_open',
            type: 'select',
            options: ['OPEN', 'ENDORSEMENT'],
            value: ''
          },
          {
            name: 'Problem',
            field: 'problem',
            type: 'boolean',
            value: false
          }
        ]
      },
      status: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.user !== null && vm.permissions.CLAIMS) {
        return true
      } else {
        vm.$router.push('/forbidden')
      }
    })
  },
  watch:{
    $route (to, from) {
      if (to.params && to.params.type && from.params.type !== to.params.type) {
        this.type = this.$route.params.type
        let userIndex = this.claimsUsers.findIndex(user => user._id === this.user._id)
        this.$store.dispatch('claims/selectUser', this.claimsUsers[userIndex !== -1 ? userIndex : 0])
        this.getClaims()
      }
    },
    selectedUser: function() {
      this.getClaims()
    },
    search: {
      immediate: false,
      deep: true,
      handler: 'getClaims'
    },
    searchDrivers (val) {
      if (val) {
        this.debounceSearch(val)
      }
    },
    page () {
      this.getClaims()
    }
  },
  computed: {
    ...mapGetters('common', ['appName', 'loading', 'dark']),
    ...mapGetters('auth', ['user', 'permissions']),
    ...mapGetters('claims', ['search', 'searchActivated', 'selectedUser', 'claimsUsers']),
    canEdit () {
      if (!this.user) return false

      if (this.permissions.CLAIMS_EDIT || this.user.roles[0].role_name === 'SUPERADMIN') {
        return true
      }
      return false
    },
    canAddNew() {
      if (!this.user) return false
      // Submit dugme disabled true/false
      if (this.permissions.CLAIMS_ADD
        || this.permissions.CLAIMS_EDIT
        || this.user.roles[0].role_name === 'SUPERADMIN') {
        return true
      }
      // Dozvoljava dodavanje za usera ciji su claims (Selectovatni user)
      // return this.selectedUser && this.selectedUser._id === this.user._id
      return false
    },
    canComment() {
      if (!this.user) return false

      if (this.permissions.CLAIMS_EDIT) return true
      else if (this.permissions.CLAIMS_COMMENT || this.user.roles[0].role_name === 'SUPERADMIN') return true

      return false
    },
    trucksListFormated() {
      return this.trucks.map(item => {
        item.text = `${item.truck_id} - ${item.name}`
        return item
      })
    },
    selectedClaimTypeName () {
      if (this.type && this.claimTypes.length) {
        return this.claimTypes.find(ty => ty.type === this.type).name
      }
      return ''
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    resetFields() {
      for(let field of this.fieldsByType[this.type]) {
        switch (field.type) {
        case 'select':
          field.value = null
          break
        case 'date':
          field.value = this.formatDate(new Date().toISOString().substr(0, 10))
          field.date = new Date().toISOString().substr(0, 10)
          break
        case 'boolean':
          field.value = false
          break
        default:
          field.value = ''
          break
        }
      }
    },
    isFieldDisabled(field) {
      // Ako je edit
      if (this.permissions.CLAIMS_EDIT) return false
      // Ako je add new
      if (!this.editId && this.permissions.CLAIMS_ADD) return false
      // Default
      const partialFieldsForEdit = ['towing', 'truck_fixed', 'trailer_fixed', 'towing_reimbursement']
      if (partialFieldsForEdit.includes(field)) return true
      return false
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    closeDatePicker (field) {
      field.show = false
      field.value = this.formatDate(field.date)
    },
    getFleetManagers(){
      this.$store.dispatch('user/getUsersByRoleId', 11).then(res => {
        this.fleetManagers = res
      })
    },
    debounceSearch: debounce( function(search) {
      if (search && search.length) {
        this.truckInputLoading = true
        callsService.getMergedDrivers(search).then(res => {
          this.truckInputLoading = false
          if (res.data.status === 'OK') {
            this.trucks = res.data.drivers
          }
        }).catch(err => {
          this.truckInputLoading = false
          console.log('trucks error search', err)
          this.trucks = []
        })
      } else {
        this.trucks = []
      }
    }, 400),
    clientFilter (item, queryText) {
      return item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    onTruckInput (index) {
      let driverNameIndex = this.fieldsByType[this.type].findIndex(item => item.field === 'driver_name')
      let trailerNameIndex = this.fieldsByType[this.type].findIndex(item => item.field === 'trailer')
      if (driverNameIndex !== -1 && this.fieldsByType[this.type][index].value)
        this.fieldsByType[this.type][driverNameIndex].value = this.fieldsByType[this.type][index].value.name
      if (trailerNameIndex !== -1 && this.fieldsByType[this.type][index].value)
        this.fieldsByType[this.type][trailerNameIndex].value = this.fieldsByType[this.type][index].value.trailer
    },
    onSubmitClaim () {
      let formObject = {}
      for(let field of this.fieldsByType[this.type]) {
        formObject[field.field] = field.value
      }
      formObject.type = this.type
      formObject.user_id = this.selectedUser._id
      if (typeof formObject.truck === 'object' && formObject.truck !== null) {
        formObject.truck = formObject.truck ? formObject.truck.truck_id : ''
      } else {
        formObject.truck = formObject.truck && formObject.truck !== '' ? formObject.truck : ''
      }
      formObject.fleet_manager = formObject.fleet_manager ? formObject.fleet_manager.name : ''
      this.$store.dispatch('common/setLoading', true)

      if (this.editId) {
        formObject._id = this.editId
        claimsService.editClaim(formObject).then(res => {
          this.editId = null
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.formDialogModal = false
            this.resetFields()
            this.getClaims()
          }
        }).catch(err => {
          this.$store.dispatch('common/setLoading', false)
          console.log('error on add claim', err)
        })
      } else {
        claimsService.addClaim(formObject).then(res => {
          this.editId = null
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.formDialogModal = false
            this.resetFields()
            this.getClaims()
          }
        }).catch(err => {
          this.$store.dispatch('common/setLoading', false)
          console.log('error on add claim', err)
        })
      }
    },
    getClaims() {
      let filters = {
        page: this.page,
        sortby: 'createdAt',
        order: -1,
        search: this.search,
        selected_userId: this.selectedUser ? this.selectedUser._id : null,
        type: this.type,
        problem: this.problemFilter,
        status: this.status
      }
      // console.log('get claims filters:', JSON.stringify(filters))
      this.$store.dispatch('common/setLoading', true)
      claimsService.getClaims(filters).then(res => {
        this.$store.dispatch('common/setLoading', false)
        this.pages = res.data.pages
        // this.page = res.data.page
        this.claims = res.data.docs
      }).catch(err => {
        this.$store.dispatch('common/setLoading', false)
        console.log('error on get claim', err)
      })
    },
    editClaim (claim) {
      this.editId = claim._id
      this.resetFields()
      for(let field of this.fieldsByType[this.type]) {
        if (field.type === 'select' && field.field === 'fleet_manager') {
          field.value = this.fleetManagers.find(fm => fm.name === claim.fleet_manager)
        } else {
          switch (field.type) {
          case 'select':
            field.value = claim[field.field]
            break
          case 'date':
            field.value = claim[field.field] ? this.formatDate(new Date(claim[field.field]).toISOString().substr(0, 10)) : this.formatDate(new Date().toISOString().substr(0, 10))
            field.date = claim[field.field] ? new Date(claim[field.field]).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
            break
          case 'boolean':
            field.value = claim[field.field]
            break
          default:
            field.value = claim[field.field]
            break
          }
        }
      }
      this.formDialogModal = true
    },
    copyClaim (claim) {
      this.copyType = this.claimTypes.find(el => el.type !== this.type).type
      this.claimToCopy = claim
      this.copyClaimModal = true
    },
    onCancelCopy () {
      this.claimToCopy = null
      this.copyType = null
      this.copyClaimModal = false
    },
    finsihCopy () {
      this.$store.dispatch('common/setLoading', true)
      const claimData = {
        type: this.copyType,
        user_id: this.selectedUser._id,
        truck: this.claimToCopy.truck,
        trailer: this.claimToCopy.trailer,
        state: this.claimToCopy.state,
        loss_date: this.claimToCopy.loss_date,
        company_name: this.claimToCopy.company_name,
        driver_name: this.claimToCopy.driver_name
      }
      claimsService.addClaim(claimData).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          this.getClaims()
          this.onCancelCopy()
        }
      }).catch(err => {
        this.$store.dispatch('common/setLoading', false)
        this.onCancelCopy()
        console.log('error on copy claim', err)
      })
    },
    deleteClaim (claim) {
      let confVal = confirm(`Are you sure you want to delete claim: ${claim.claim_number}?`)
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        claimsService.deleteClaim(claim._id).then(() => {
          this.$store.dispatch('common/setLoading', false)
          this.getClaims()
        }).catch(err => {
          this.$store.dispatch('common/setLoading', false)
          console.log('error on delete claim', err)
        })
      }
    },
    onCancel () {
      this.formDialogModal = false
      this.editId = null
      this.resetFields()
    },
    openComments (claim) {
      this.commentsModal = true
      this.selectedClaim = claim
    },
    addComment () {
      if (!this.canComment || this.commentText.length > 500) return
      let comment = {
        text: this.commentText.trim()
      }
      this.$store.dispatch('common/setLoading', true)
      if (this.editCommentId !== null) {
        comment['_id'] = this.editCommentId
        claimsService.editComment(this.selectedClaim._id, comment).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.editCommentId = null
            this.commentText = ''
            let claimIndex = this.claims.findIndex(claim => claim._id === this.selectedClaim._id)
            if (claimIndex !== -1) {
              this.claims[claimIndex].comments = res.data.claim.comments
            }
            this.setMessage('Comment successfully edited')
          }
        })
      } else {
        claimsService.addComment(this.selectedClaim._id, comment).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.commentText = ''
            let claimIndex = this.claims.findIndex(claim => claim._id === this.selectedClaim._id)
            if (claimIndex !== -1) {
              this.claims[claimIndex].comments = res.data.claim.comments
            }
            this.setMessage('Comment successfully added')
          }
        })
      }
    },
    handleEnter (e) {
      if (e.ctrlKey) {
        this.addComment()
      }
    },
    onEditComment (comment) {
      this.editCommentId = comment._id
      this.commentText = comment.text
      this.$refs.comtext.$refs.input.focus()
    },
    onDeleteComment (commentId) {
      this.$store.dispatch('common/setLoading', true)
      claimsService.deleteComment(this.selectedClaim._id, commentId).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          let claimIndex = this.claims.findIndex(claim => claim._id === this.selectedClaim._id)
          if (claimIndex !== -1) {
            this.claims[claimIndex].comments = res.data.claim.comments
          }
          this.setMessage('Comment successfully deleted')
        }
      })
    },
    cancelCommentEdit () {
      this.editCommentId = null
      this.commentText = ''
    },
    onCloseCommentsModal () {
      this.selectedClaim = null
      this.commentsModal = false
    }
  },
  mounted() {
    if (this.$route.params && this.$route.params.type) {
      this.type = this.$route.params.type
      this.$store.dispatch('claims/getTypes').then(res => {
        if (res.status === 'OK') {
          this.claimTypes = res.types
          this.copyType = this.claimTypes.find(el => el.type !== this.type).type
        }
      })
      this.getFleetManagers()
      this.getClaims()
    }

    if (this.user.roles[0].role_name === 'SUPERADMIN' || this.permissions.CLAIMS_CLOSE) {
      let indexBob = this.fieldsByType['BOBTAIL_INSURANCE'].findIndex(field => field.field === 'is_open')
      let indexCargo = this.fieldsByType['CARGO_CLAIMS'].findIndex(field => field.field === 'is_open')
      this.fieldsByType['BOBTAIL_INSURANCE'][indexBob].options = ['OPEN', 'CLOSED', 'ENDORSEMENT']
      this.fieldsByType['CARGO_CLAIMS'][indexCargo].options = ['OPEN', 'CLOSED', 'ENDORSEMENT']
    }
  }
}
</script>

<style lang="scss">

.claims-add-btn {
  top: 6px;
  right: 6px;
  position: absolute;
  margin: 0 0 16px 16px;
}

.claim-type-title {
  position: absolute;
  left: 13px;
  top: 21px;
}

// .chackbox-filter {
//   position: absolute;
//   top: -4px;
//   left: 200px;
// }

.table-filters {
  display: flex;
  margin-left: 180px;
  align-items: center;

  .chackbox-filter {
    margin-right: 16px;
    .v-input--selection-controls {
      margin-top: 0px;
    }
  }
}

.table-container-claims {
  overflow: scroll;
  // max-width: 100%;
  width: 100%;
  max-height: calc(100vh - 145px);
  position: relative;
  margin-top: 16px;
  // margin-right: 12px;
  // margin-left: 12px;

  &.loading {
    opacity: 0.7;
  }

  .main-table-claims {
    border-collapse: collapse;
    min-width: 400px;
    width: 100%;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;

    &.dark {
      th {
        background-color: #43a047;
      }

      tbody {
        tr {
          &:hover {
            color: #000;
          }
        }
      }
    }

    th {
      padding: 5px 5px;
      border-left: 1px solid #A9A9A9;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      background-color: #1E88E5FF;
      color: #ffffff;
      text-align: center;
      top: 0;
      z-index: 3;
      font-size: 13px;
    }

    tbody {
      tr {
        &:hover {
          background-color: lightyellow !important;
        }

        &.problem {
          td {
            color: #e91e63 !important;
            font-weight: bold;
          }
        }

        td {
          padding: 7px 5px;
          font-size: 12px;
          border-left: 1px solid #A9A9A9;
          border-bottom: 1px solid #A9A9A9;
          white-space: nowrap;
          position: relative;
        }
      }
    }
  }
}

</style>