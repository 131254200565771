<template>
  <v-app id="inspire">
    <v-main>
      <Snackbar/>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-alert
              :value="message !== ''"
              :type="messageType"
              transition="scale-transition"
            >
              {{message}}
            </v-alert>
            <v-card class="elevation-12">
              <v-form @submit.prevent="onLogin">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Login to {{appName}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-text-field :loading="loading" placeholder="Email" :autofocus="true" v-model="loginForm.email" prepend-icon="mdi-account" name="login" label="Login" type="text"></v-text-field>
                    <v-text-field
                    :loading="loading"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="loginForm.password"
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    @click:append="showPassword = !showPassword"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="primary" type="button" to="/reset-password">Forgotten password?</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">Login</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Snackbar from '../Common/Snackbar'
import { getFingerprintLocalCache, cacheFingerprintData } from '../../utils'
export default {
  components: {
    Snackbar
  },
  data: () => ({
    appName: process.env.VUE_APP_APP_NAME,
    showPassword: false,
    message: '',
    messageType: 'warning',
    loginForm: {
      email: '',
      password: ''
    }
  }),
  methods: {
    async onLogin () {
      
      let visitorData = getFingerprintLocalCache()
      if (!visitorData) {
        visitorData = await this.$fpjs.getVisitorData({
          extendedResult: true
        })
        cacheFingerprintData(visitorData)        
      }

      try {
        const response = await this.$store.dispatch('auth/login', {...this.loginForm, visitorData})
        if (response.data.error && response.data.status === 'WRONG_CREDENTIALS') {
          this.message = this.$t('user.loginError')
        } else {
          const {user} = response.data
          const msUser = await this.$store.dispatch('auth/msLogin', this.loginForm)
          if (msUser) {
            console.log('User logged in to auth service:', msUser.email)
          }

          if (user.board_id !== null){
            this.$store.dispatch('dashboard/setBoardId', user.board_id)
          }
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push('/')
          }

          // else if (user.roles[0].path && user.roles[0].path !== '' && user.roles[0].path !== null) {
          //   this.$router.push(user.roles[0].path)
          // }
        }
      } catch (err) {
        if(err.status === 410){
          this.$router.push({path: `otp-validate/${this.loginForm.email}`})
        }
      }
    }
  },
  computed: {
    ...mapGetters('common', ['loading'])
  },
  mounted() {
    if (this.$route.query.fromOtp) {
      this.message = 'VALID OTP TOKEN! You can Login now!',
      this.messageType = 'success'
    }
  }
}
</script>
