<template>
  <v-col :cols="isActive ? 8 : 4">
    <v-lazy
      min-height="200"
      transition="scale-transition"  
    >
      <v-card
        min-height="200"
        elevation="2"
        outlined
      >
        <v-card-title class="pa-2">
          <span @click="setActiveFm(user._id)" class="overline fm-name" :class="{active: isActive}">{{user.name}}</span>
          <v-spacer></v-spacer>
          <v-avatar
            size="28"
            small
            class="teal"
          >
            <span class="white--text subtitle-1">{{user.countActive}}</span>
          </v-avatar>
          <v-avatar v-if="showInactive && user.drivers['Inactive']"
            size="28"
            small
            class="teal ml-2"
            color="warning"
          >
            <span class="white--text subtitle-1">{{user.drivers['Inactive'] && user.drivers['Inactive'].length || 0}}</span>
          </v-avatar>
        </v-card-title>
        <draggable
          class="drivers"
          @add="onAddDriver($event, user)"
          :sort="false"
          :group="{ name: user._id, pull: false, put: true }"
        >
          <div v-for="(board, index) of user.drivers" :key="`board-${index}`">
            <template v-if="!showInactive && index === 'Inactive'"></template>
            <template v-else>
              <div class="board-title">
                <p class="subtitle-2 mb-0">{{index}}</p>
                <span class="subtitle-2">{{user.drivers[index].length}}</span>
              </div>
              <v-divider class="mb-1"></v-divider>
              <v-chip
                class="mr-2 mb-1"
                v-for="(driver, dIndex) in user.drivers[index]"
                :color="!driver.is_active ? 'warning' : ''"
                :key="`${driver._id}_${dIndex}`"
                :data-id="driver._id"
                label
                close
                small
                @click:close="removeDriver(driver._id, index, user)"
              >
                <template v-if="!search || !search.length">
                  <strong>{{driver.truck}}</strong> - {{driver.name}}
                </template>
                <template v-else>
                  <span v-html="$options.filters.highlight(driver.truck, search)"></span> - {{driver.name}}
                </template>
              </v-chip>
              
            </template>
          </div>
          
        </draggable>
      </v-card>
    </v-lazy>
  </v-col>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'FmCard',
  props: ['user', 'isActive', 'showInactive', 'search'],
  components: {
    draggable
  },
  data: () => ({
    isLoaded: false
  }),
  methods: {
    setActiveFm (id) {
      this.$emit('setActiveFm', id)
    },
    onAddDriver (event, fmUser) {
      this.$emit('addDriver', {event, fmUser})
    },
    removeDriver (driverId, board, fmUser) {
      this.$emit('removeDriver', {driverId, board, fmUser})
    }
  }
}
</script>

<style lang="scss">
.fm-name {
  cursor: pointer;
  &.active, &:hover {
    color: #009688;
  }
}

.drivers {
  padding: 10px;
  width: 100%;
  height: 195px;
  overflow-y: scroll;
}

.board-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>