import auth from '../../services/AuthService'
import msShopService from '../../services/MsShopService'
import chatService from '../../services/ChatService'

const state = {
  user: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : null,
  msUser: localStorage.getItem('msUser') ? JSON.parse(localStorage.getItem('msUser')) : null,
  permissions: localStorage.getItem('user_permissions') ? JSON.parse(localStorage.getItem('user_permissions')) : {},
  chatGroups: [],
  inactiveModal: false,
  otpValid: false
}

const getters = {
  isLoggedIn: (state) => {
    return state.user !== null
  },
  isSuperAdmin: (state) => {
    if (state.user !== null && state.user.roles.findIndex(role => role.role_id === 1) !== -1) {
      return true
    } else {
      return false
    }
  },
  getRoles: (state) => {
    return state.user !== null && state.user.roles.map(role => role.role_name)
  },
  user: (state) => {
    return state.user
  },
  msUser: (state) => {
    return state.msUser
  },
  permissions: (state) => {
    return state.permissions
  },
  inactiveModel: (state) => {
    return state.inactiveModal
  },
  chatGroups: (state) => {
    return state.chatGroups
  }
}

const actions = {
  login ({commit, dispatch}, data) {
    dispatch('common/setLoading', true, {root: true})
    return auth.login(data).then(res => {
      // Add authorization header
      dispatch('common/setLoading', false, {root: true})
      if (!res.data.error && res.data.status !== 'WRONG_CREDENTIALS') {
        localStorage.setItem('user_data', JSON.stringify(res.data.user))
        commit('setUser', res.data.user)
        commit('setPermissions', res.data.permissions)
        localStorage.setItem('user_permissions', JSON.stringify(res.data.permissions))
        return Promise.resolve(res)
      } else {
        return Promise.resolve(res)
      }
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  msLogin({commit, dispatch}, data) {
    dispatch('common/setLoading', true, {root: true})
    return msShopService.auth(data).then(res => {
      dispatch('common/setLoading', false, {root: true})
      if (res.status === 201) {
        localStorage.setItem('msAuthKey', JSON.stringify(res.data.accessToken))
        localStorage.setItem('msRefreshToken', JSON.stringify(res.data.refreshToken))
        localStorage.setItem('msUser', JSON.stringify(res.data.user))
        commit('setMsUser', res.data.user)
        return Promise.resolve(res.data.user)
      } else {
        return Promise.resolve(false)
      }
    }).catch(err => {
      console.log('error on login to auth service', err)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(false)
    })
  },
  logout ({dispatch, commit}) {
    dispatch('common/setLoading', true, {root: true})
    return auth.logout().then(async res => {
      // clear authorization header
      localStorage.removeItem('user_data')
      localStorage.removeItem('user_permissions')

      // clear Ms keys
      await msShopService.logout()
      localStorage.removeItem('msAuthKey')
      localStorage.removeItem('msRefreshToken')
      localStorage.removeItem('msUser')
      commit('setUser', null)
      commit('setMsUser', null)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  logoutLocal ({commit}) {
    // clear authorization header
    localStorage.removeItem('user_data')
    localStorage.removeItem('user_permissions')
    commit('setUser', null)
    commit('setMsUser', null)
  },
  showInactiveModal({commit}, payload) {
    commit('setInactiveModal', payload)
  },
  getChatGroups({commit}) {
    return chatService.getUserGroups().then(response => {
      if (response.data.groups) {
        commit('setChatGroups', response.data.groups)
        return Promise.resolve(response.data.groups)
      }
    }).catch(err => {
      console.log('Error on get chat groups:', err)
      return Promise.reject(err)
    })
  },
  verifyOtp({commit}, payload) {
    return auth.verifyOtp(payload).then(response => {
      if (response.data) {
        commit('setOtpTokenStatus', response.data)
        return Promise.resolve(response.data)
      }
    }).catch(err => {
      console.log('Error on get chat groups:', err)
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setMsUser (state, user) {
    state.msUser = user
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setInactiveModal (state, modal) {
    state.inactiveModal = modal
  },
  setPermissions (state, data) {
    state.permissions = data
  },
  setChatGroups (state, data) {
    state.chatGroups = data
  },
  setOtpTokenStatus (state, data) {
    state.otpValid = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
