import Api from '@/services/Api'

export default {
  getDrivers (pagination) {
    return Api().get('/drivers', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1,
      search: pagination.search,
      deleted: pagination.deleted,
      searchBy: pagination.searchBy
    }})
  },
  exportDrivers (data) {
    return Api().post('/drivers/export', {
      search: data.search,
      deleted: data.deleted,
      searchBy: data.searchBy
    })
  },
  addDriver(driver) {
    return Api().post('/drivers', driver)
  },
  editDriver (driver) {
    return Api().put('/drivers', driver)
  },
  deleteDriver (id) {
    return Api().delete(`/drivers/${id}`)
  },
  getAllDrivers () {
    return Api().get('/drivers/all')
  },
  getDriversAutocomplete (search) {
    return Api().get(`/drivers/autocomplete/${search}`)
  },
  getDriver(id){
    return Api().get(`/drivers/${id}`)
  },
  getDriverFmByTruck(truck){
    return Api().get(`/drivers/fm-by-truck/${truck}`)
  },
  filterDashboard(data) {
    return Api().post('/drivers/dashboard', data)
  },
  updateDispatchersDriver(dispatcherId, driverId, boardId){
    return Api().put('/drivers/update-driver-dispatcher', {dispatcherId, driverId, boardId})
  },
  getDriversByFm (fmId) {
    return Api().get(`/drivers/get-drivers-by-fleet-manager/${fmId}`)
  },
  getDriversHasNoFm () {
    return Api().get('/drivers/get-drivers-has-no-fleet-manager')
  },
  updateDriverFm (driverId, fleetManager) {
    return Api().put('/drivers/update-drivers-fleet-manager', {driverId, fleetManager})
  },
  getBrokenTrucks (id, search = '') {
    let url = `/drivers/broken-trucks?fmId=${id}`
    if (search && search.length) {
      url += `&search=${search}`
    }
    return Api().get(url)
  },
  getDriversGroupedByFm (data) {
    return Api().get('/drivers/drivers-grouped-by-fm', {params: data})
  }
}
