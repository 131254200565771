<template>
  <div class="trucks-wrapper">
    <v-row class="pa-1 d-flex align-center">
      <v-btn
        :disabled="!canEdit"
        :loading="loading"
        color="pink"
        class="ml-2 mr-1"
        dark
        small
        @click="formDialogModal = true"
      >
        Add <v-icon small>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="mr-1"
        dark
        small
        :to="{ name: 'statistics' }"
      >
        Stats <v-icon small>mdi-chart-bar</v-icon>
      </v-btn>
      <v-btn
        color="success"
        :loading="loading"
        class="mr-1"
        dark
        small
        @click="exportData"
      >
        Export <v-icon small>mdi-file-excel-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <table-filters
        v-if="filtersReady"
        :reset-values="resetValues"
        :initial-values="{ ...FILTER_INITIAL }"
        :initial-form="FILTERS_FORM"
        :extra-fields="FILTERS_FORM_ADDITIONAL"
        @filters-changed="handleFiltersChanged"
      />
    </v-row>

    <span class="total-number">
      Trucks total: <strong>{{ totalItems }}</strong>
    </span>
    <div class="Trucks__table-wrapper">
      <div class="table-container-trucks">
        <table class="trucks-table" :class="{ dark: dark }">
          <thead>
            <tr>
              <th>#</th>
              <th align="center">Truck #</th>
              <th v-for="item in headers" :key="item.value">{{ item.text }}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(truck, index) in trucks"
              :key="truck.id"
              :class="{ total_damaged: truck.total_damage }"
              :title="truck.total_damage ? 'Total damage' : ''"
            >
              <td align="center">{{ index + 1 }}</td>
              <td class="truck-number">
                <TruckColumn :truck="truck" @right-click="editTruck(truck)" />
              </td>

              <template v-for="(item, indexH) in headers">
                <td
                  align="left"
                  v-if="item.value === 'current_driver.name'"
                  :key="`td-${indexH}`"
                >
                  <template v-if="truck.is_third_party && truck.owner">
                    {{ truck.owner.name }}
                  </template>
                  <template v-else>
                    {{
                      truck.current_driver
                        ? $options.filters.driverName(truck.current_driver.name)
                        : '/'
                    }}
                  </template>
                </td>

                <td
                  v-else-if="item.value === 'division'"
                  align="center"
                  :key="`td-${indexH}1`"
                  :class="{ 'history-field': truck.division }"
                >
                  <field-with-history
                    type="DIVISION_CHANGED"
                    :truck-id="truck._id"
                    show-actions
                  >
                    {{ truck.division }}
                  </field-with-history>
                </td>

                <td
                  v-else-if="item.value === 'oil_change'"
                  :key="`td-${indexH}2`"
                  align="center"
                >
                  <oil-change-history v-if="truck.number" :truck="truck" />
                </td>

                <td
                  v-else-if="item.value === 'plate_number'"
                  :key="`td-${indexH}3`"
                  align="center"
                >
                  <field-with-history
                    :truck-id="truck._id"
                    type="PLATE_CHANGED"
                  >
                    {{ truck.plate_number }}
                  </field-with-history>
                </td>

                <td
                  v-else-if="item.value === 'annual_dot'"
                  :key="`td-${indexH}4`"
                  align="center"
                >
                  <truck-dot-inspection v-if="truck.number" :truck="truck" />
                </td>

                <td
                  v-else-if="item.value === 'repairs'"
                  :key="`td-${indexH}5`"
                  align="center"
                >
                  <truck-repairs v-if="truck.number" :truck="truck" />
                </td>

                <td
                  v-else-if="item.value === 'on_the_road'"
                  :key="`td-${indexH}6`"
                  align="center"
                >
                  <truck-location
                    :truck="truck"
                    @location-changed="handleUpdateLocation"
                  />
                </td>

                <td
                  v-else-if="booleanColumns.includes(item.value)"
                  :key="`td-${indexH}7`"
                  align="center"
                >
                  <v-icon color="indigo" v-if="truck[item.value]"
                    >mdi-check</v-icon
                  >
                  <v-icon color="red lighten-2" v-else
                    >mdi-close-octagon-outline</v-icon
                  >
                </td>

                <td
                  v-else-if="item.value === 'start_date'"
                  align="center"
                  :key="`td-${indexH}8`"
                >
                  <note
                    :disable="!canEdit"
                    :text="`${
                      truck.start_date
                        ? $options.filters.formatDate(truck.start_date)
                        : ''
                    }${
                      truck.start_date_second
                        ? ` / ${$options.filters.formatDate(
                            truck.start_date_second
                          )}`
                        : ''
                    }`"
                    :note="truck.aStartDateNote"
                    @dialog-submit="
                      handleNoteChange('aStartDateNote', truck, $event)
                    "
                  />
                </td>

                <td
                  v-else-if="item.value === 'aOwner'"
                  align="center"
                  :key="`td-${indexH}8`"
                  style="padding: 0"
                >
                  <note
                    :disable="!canEdit"
                    :text="truck.aOwner"
                    :note="truck.aOwnerNote"
                    @dialog-submit="
                      handleNoteChange('aOwnerNote', truck, $event)
                    "
                  />
                </td>
                <td
                  v-else-if="item.value === 'noteNew'"
                  align="center"
                  :key="`td-${indexH}8`"
                  style="
                    padding: 0;
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 0;
                  "
                >
                  <note
                    :disable="!canEdit"
                    :text="truck.noteNew"
                    :note="truck.noteNew"
                    @dialog-submit="handleNoteChange('noteNew', truck, $event)"
                  />
                </td>

                <td v-else :key="`td-${indexH}9`" align="center">
                  {{ getValue(item.value, truck) }}
                </td>
              </template>

              <td align="center">
                <v-icon
                  :disabled="!canEdit"
                  small
                  class="mr-2"
                  color="green lighten-2"
                  @click="editTruck(truck)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon v-if="permissions.PERMITBOOK_ADD" small class="mr-2" @click="addDocumentForThisTruck(truck)">
                  mdi-upload
                </v-icon>
                <v-icon v-if="permissions.PERMITBOOK_VIEW" small @click="showDocumentsForThisTruck(truck)">
                  mdi-file-document-multiple
                </v-icon>
              </td>
            </tr>
          </tbody>
        </table>
        <infinite-loading
          ref="infinite"
          :identifier="optionsString"
          @infinite="infiniteHandler"
        >
          <div slot="spinner">
            <v-progress-circular
              indeterminate
              class="trailer-loader"
              color="primary"
            ></v-progress-circular>
          </div>
          <span slot="no-more"></span>
          <div slot="no-results">
            <p
              class="subtitle-1 pt-2"
              style="text-align: center"
              v-if="!trucks.length"
            >
              There is no Trucks
            </p>
          </div>
        </infinite-loading>
      </div>

      <div class="Trucks__table-order">
        <table-order
          :userId="user && user._id"
          :default-order="defaultHeaders"
          @order-changed="handleTableOrderChanged"
          table-name="TRUCKS"
        />
      </div>
    </div>

    <!-- DIALOG FORM -->
    <v-dialog v-model="formDialogModal" width="800px" persistent>
      <v-card>
        <truck-form
          v-if="formDialogModal"
          ref="truckForm"
          :truck="selectedTruck"
          :activeFilter="options.filter"
          @submit="onAddTruck"
          @close="closeForm"
        ></truck-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showForm" max-width="500">
      <div>
        <PermitBookForm
          v-if="showForm"
          @permitBookAdded="showForm = false"
          :referrerForDocument="truckForDocument"
        />
      </div>
    </v-dialog>
    <v-dialog v-model="showDocumentsModal" max-width="90vw" max-height="90vh">
      <PermitBookTable
        :referrer-type="'truck'"
        :referrer-id="referrerId"
        v-if="showDocumentsModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import truckService from '../../services/TruckService'
import InfiniteLoading from 'vue-infinite-loading'
import debounce from 'lodash/debounce'
import TruckColumn from './TruckColumn.vue'
import TruckForm from './TruckForm.vue'
import TableOrder from '../TableOrder.vue'
import PermitBookForm from '../PermitBooks/PermitBookForm.vue'
import PermitBookTable from '../PermitBooks/PermitBookTable.vue'

import OilChangeHistory from './OilChangeHistory.vue'
import FieldWithHistory from './FieldWithHistory.vue'
import TruckDotInspection from './TruckDotInspection.vue'
import TruckLocation from './TruckLocation.vue'
// import LocationForm from './LocationForm.vue'
import TruckRepairs from './TruckReparis.vue'
import Note from '../Common/NoteColumn.vue'

// import TrailerNumber from '../Trailer/TrailerNumber.vue'
import { b64toBlob, downloadFile } from '../../utils'

import TableFilters from '../TableFilters.vue'

const DEFAULT_HEADERS = [
  // { text: 'Trl #', value: 'current_trailer.number' },
  { text: 'Drivers', value: 'current_driver.name' },
  { text: 'Info', value: 'info' },
  { text: 'Phone', value: 'phone' },
  { text: 'Note', value: 'noteNew' },
  { text: 'Division', value: 'division' },
  { text: 'Start date', value: 'start_date' },
  { text: 'Owner', value: 'aOwner' },
  { text: 'Make', value: 'make.name' },
  { text: 'Model', value: 'model.name' },
  { text: 'Color', value: 'color' },
  { text: 'Year', value: 'year' },
  { text: 'Vin #', value: 'vin_number' },
  { text: 'Plate', value: 'plate_number' },
  { text: 'Engine', value: 'engine' },
  { text: 'Transmission', value: 'transmission' },
  { text: 'Mileage', value: 'mileage' },
  { text: 'Oil Change History', value: 'oil_change' },
  { text: 'Pro plan', value: 'pro_plan' },
  { text: 'Rented', value: 'rent' },
  { text: 'Annual DOT', value: 'annual_dot' },
  { text: 'Repairs', value: 'repairs' },
  { text: 'Is Broken', value: 'broken' },
  { text: 'Is Damaged', value: 'total_damage' },
  { text: 'On road', value: 'on_the_road' },
]

const FILTER_INITIAL = {
  filter: 'all',
  search: '',
  company: '',
  companyFrom: null,
  companyTo: null,
}

const FILTERS_FORM = {
  filter: {
    type: 'radio-group',
    options: [
      { label: 'All', value: 'all' },
      { label: 'In company', value: 'in_company' },
      { label: 'Third Party', value: 'is_third_party' },
      { label: 'Inactive trucks', value: 'released' },
      // { label: 'Pro Plan', value: 'pro_plan' },
      // { label: 'On the road', value: 'on_the_road' },
      // { label: 'Total Damage', value: 'total_damage' },
      // { label: 'Broken', value: 'broken' }
    ],
  },
  search: { type: 'text', label: 'Search trucks' },
}

const FILTERS_FORM_ADDITIONAL = {
  company: { type: 'select', label: 'Company', options: [] },
  companyFrom: { type: 'date-time', label: 'From' },
  companyTo: { type: 'date-time', label: 'Until' },
}

const BOOLEAN_COLUMNS = [
  'broken',
  'total_damage',
  'pro_plan',
  'rent',
  'is_third_party',
]

export default {
  name: 'Trucks',
  components: {
    InfiniteLoading,
    TruckColumn,
    OilChangeHistory,
    FieldWithHistory,
    TruckDotInspection,
    TruckLocation,
    // LocationForm,
    TruckRepairs,
    TableOrder,
    TableFilters,
    Note,
    TruckForm,
    PermitBookForm,
    PermitBookTable
  },
  data() {
    return {
      resetValues: { ...FILTER_INITIAL },
      FILTER_INITIAL,
      filtersReady: true,
      FILTERS_FORM,
      FILTERS_FORM_ADDITIONAL,
      options: {
        page: 1,
        sortby: ['number'],
        order: 1,
        itemsPerPage: 50,
        ...FILTER_INITIAL,
      },
      totalItems: 0,
      headers: DEFAULT_HEADERS,
      defaultHeaders: DEFAULT_HEADERS,
      booleanColumns: BOOLEAN_COLUMNS,
      selectedTruck: null,
      trucks: [],
      truckForDocument: null,
      showForm: false,
      showDocumentsModal: false,
      referrerId: null
    }
  },
  computed: {
    ...mapGetters('common', ['dark', 'loading', 'companiesAll']),
    ...mapGetters('auth', ['user', 'permissions']),
    ...mapGetters('trucks', ['formDialog']),
    formDialogModal: {
      get() {
        return this.formDialog
      },
      set(value) {
        if (!value) {
          this.driver_id = null
        }
        this.$store.dispatch('trucks/setFormDialog', value)
      },
    },
    optionsString() {
      return JSON.stringify(this.options)
    },
    trucksMap() {
      let trucksMap = {}
      for (const [y, truck] of this.trucks.entries()) {
        trucksMap[truck._id] = y
      }
      return trucksMap
    },
    canEdit() {
      return this.permissions.TRUCK_EDIT
    },
    canDeletePermanent() {
      return this.permissions.TRUCK_DELETE
    },
    releasedFilterActive() {
      return this.options.filter === 'released'
    },
  },
  sockets: {
    connect() {
      this.$socket.client.emit('joinToTruck')
    },
    disconnect() {
      console.log('client disconnected')
    },
    newTruck(truck) {
      if (this.options.filter !== 'is_active') {
        this.trucks.push(truck)
        this.totalItems += 1
      }
    },
    editTruck(data) {
      if (this.trucksMap[data._id] != undefined) {
        let index = this.trucksMap[data._id]
        this.trucks.splice(index, 1, data)
      }
    },
    deleteTruck(data) {
      if (this.trucksMap[data._id] != undefined) {
        let index = this.trucksMap[data._id]
        this.trucks.splice(index, 1)
        this.totalItems = this.totalItems - 1
        delete this.trucksMap[data._id]
      }
    },
    releasedTruck(data) {
      if (this.trucksMap[data._id] != undefined) {
        let index = this.trucksMap[data._id]
        this.trucks.splice(index, 1)
        this.totalItems = this.totalItems - 1
        delete this.trucksMap[data._id]
      }
    },
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),
    handleFiltersChanged: debounce(function (filters) {
      this.trucks = []
      this.checkUrl(this.options.filter, filters.filter)
      this.options = {
        ...this.options,
        ...filters,
        page: 1,
      }
    }, 500),
    checkUrl(oldF, newF) {
      if (oldF !== newF) this.$router.push(`/trucks/${newF}`)
    },
    handleNoteChange(value, truck, note) {
      if (value) {
        const tmpTruck = { ...truck }
        tmpTruck[value] = note
        this.onAddTruck(tmpTruck)
      }
    },
    onAddTruck(form) {
      if (form._id) {
        this.$store
          .dispatch('trucks/editTruck', form)
          .then((truck) => {
            if (truck) {
              this.closeForm()
            }
          })
          .catch((err) => {
            if (err.data && err.data.code === 11000) {
              let key = Object.keys(err.data.keyPattern)[0]
              this.$refs.truckForm.errors.add({
                scope: 'truck',
                field: key,
                msg: 'This field must be unique',
              })
            }
          })
      } else {
        this.$store
          .dispatch('trucks/addTruck', form)
          .then((res) => {
            if (res) {
              this.closeForm()
              this.setMessage('A new truck has been added')
            }
          })
          .catch((err) => {
            if (err.data && err.data.code === 11000) {
              let key = Object.keys(err.data.keyPattern)[0]
              this.$refs.truckForm.errors.add({
                scope: 'truck',
                field: key,
                msg: 'This field must be unique',
              })
            }
          })
      }
    },
    closeForm() {
      this.selectedTruck = null
      this.$refs.truckForm.resetForm()
      this.formDialogModal = false
    },
    infiniteHandler($state) {
      this.$store.dispatch('common/setLoading', true)
      truckService
        .getTrucks(this.options)
        .then((res) => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.docs && res.data.docs.length) {
            this.trucks.push(...res.data.docs)
            this.totalItems = res.data.total
            if (this.trucks.length === res.data.total) {
              $state.complete()
            } else {
              this.options.page++
              $state.loaded()
            }
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          console.log('err get trucks', err)
        })
    },
    handleUpdateLocation({ lat, lng, onTheRoad, truck }) {
      if (!this.permissions.TRUCK_EDIT) return
      const tmpTruck = {
        ...truck,
        on_the_road: onTheRoad,
        lat,
        lng,
      }
      this.onAddTruck(tmpTruck)
    },
    editTruck(truck) {
      this.selectedTruck = { ...truck }
      this.formDialogModal = true
    },
    getValue(value, truck) {
      if (typeof value !== 'string') return '/'
      const [first, second] = value.split('.')
      if (second) return (truck[first] && truck[first][second]) || '/'
      return truck[first] || '/'
    },
    handleTableOrderChanged(order) {
      const prepareObj = {}
      order.forEach((c) => {
        prepareObj[c.columnName] = {
          order: c.order,
          isShown: c.isShown,
        }
      })

      this.headers = DEFAULT_HEADERS.filter(
        (c) => prepareObj[c.value].isShown
      ).sort((a, b) => prepareObj[a.value].order - prepareObj[b.value].order)
    },
    exportData() {
      this.$store
        .dispatch('trucks/exportToExcel', {
          headers: this.headers,
          options: this.options,
        })
        .then((file) => {
          const blob = b64toBlob(
            file.data,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          )
          downloadFile(blob, 'truck_export')
        })
    },
    setCompanies() {
      this.$store.dispatch('common/getAllCompanies').then((companies) => {
        if (companies) {
          this.FILTERS_FORM_ADDITIONAL.company.options = companies
        }
      })
    },
    addDocumentForThisTruck(truck) {
      this.showForm = true
      const truckObj = {
        type: 'truck',
        text: truck.number,
        value: {
          number: truck.number,
          id: truck._id,
        },
      }
      this.truckForDocument = truckObj
    },
    showDocumentsForThisTruck(truck) {
      this.referrerId = truck._id
      this.showDocumentsModal = true
    }
  },
  mounted() {
    this.$socket.client.emit('joinToTruck')
    this.setCompanies()

    if (
      this.canDeletePermanent &&
      this.FILTERS_FORM.filter.options.findIndex(
        (filter) => filter.value === 'is_active'
      ) === -1
    ) {
      this.FILTERS_FORM.filter.options.push({
        label: 'Deleted',
        value: 'is_active',
      })
    }
  },
  beforeDestroy() {
    this.$socket.client.emit('leave_truck')
  },
  beforeMount() {
    if (this.$route.query.search) {
      FILTER_INITIAL.search = this.$route.query.search
      this.options.search = this.$route.query.search
    }

    if (this.$route.params.filter) {
      if (this.$route.params.filter === 'is_active' && !this.canDeletePermanent)
        return

      FILTER_INITIAL.filter = this.$route.params.filter
      this.options.filter = this.$route.params.filter
    }

    if (this.$route.query && this.$route.query.search) {
      FILTER_INITIAL.search = this.$route.query.search
      this.options.search = this.$route.query.search
    }
  },
}
</script>

<style lang="scss">
.Trucks {
  &__table-wrapper {
    position: relative;
  }

  &__table-order {
    position: absolute;
    right: -6px;
    top: -20px;
    z-index: 10;
  }
}

.trucks-wrapper {
  .v-input--selection-controls {
    margin-top: 6px;
  }

  .total-number {
    font-size: 10px;
  }

  .table-container-trucks {
    overflow: scroll;
    width: 100%;
    max-height: calc(100vh - 165px);
    position: relative;

    &.loading {
      opacity: 0.7;
    }

    .trucks-table {
      border-collapse: collapse;
      min-width: 400px;
      width: 100%;
      border-collapse: separate; /* Don't collapse */
      border-spacing: 0;

      &.dark {
        th {
          background-color: #43a047;
        }

        tbody {
          tr {
            &:hover {
              color: #000;

              td {
                &.truck-number {
                  .note-icon {
                    color: #43a047;
                  }
                }
              }
            }

            td {
              &.truck-number {
                background: #121212;
              }
            }
          }
        }
      }

      th {
        padding: 5px 5px;
        border-left: 1px solid #a9a9a9;
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        left: 0px;
        background-color: #1e88e5ff;
        color: #ffffff;
        text-align: center;
        top: 0;
        z-index: 3;
        font-size: 13px;

        &:nth-child(2) {
          z-index: 4;
          border-right: 2px solid yellow;
        }

        &:nth-child(4) {
          min-width: 60px;
        }
      }

      tbody {
        tr {
          &.total_damaged {
            background-color: rgb(228, 174, 167) !important;

            td {
              &.truck-number {
                background-color: rgb(228, 174, 167) !important;
              }
            }

            &:hover {
              background-color: rgb(228, 174, 167) !important;

              td {
                &.truck-number {
                  background-color: rgb(228, 174, 167) !important;
                }
              }
            }
          }

          &:hover {
            background-color: lightyellow !important;

            td {
              &.truck-number {
                background-color: lightyellow !important;
              }
              .separator-cell {
                background-color: transparent;
              }
            }
          }

          td {
            padding: 4px 5px;
            font-size: 12px;
            border-left: 1px solid #a9a9a9;
            border-bottom: 1px solid #a9a9a9;
            white-space: nowrap;
            position: relative;

            &.truck-number {
              padding: 0px;
              cursor: pointer;
              font-weight: bold;
              min-width: 75px;
              position: sticky;
              position: -webkit-sticky; /* for Safari */
              left: 0px;
              z-index: 2;
              background: #fff;
              border-right: 2px solid yellow;
              padding: 0px;
              &:hover {
                color: blue;
              }

              .truck-content {
                text-align: center;
                width: 100%;
                min-height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .note-icon {
                position: absolute;
                right: 2px;
                top: 2px;
                font-size: 20px;
              }
            }

            &.trailer-number {
              cursor: pointer;
              font-weight: bold;
            }

            &.history-field {
              cursor: pointer;
              &:hover {
                color: blue;
              }
            }

            &:nth-child(4) {
              cursor: pointer;
              font-weight: 600;
              padding: 0px;

              div {
                padding: 7px 5px;
              }
            }
          }

          .separator-cell {
            background-color: #ccc;
            border-bottom: none;
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }
  }
}

.v-input--switch {
  .text {
    font-size: 13px;
  }
}
</style>
