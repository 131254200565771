<template>
  <v-app id="inspire">
    <Snackbar />
    <Mnavigation :footer-on="showFooter"/>
    <Toolbar v-if="!tvview"></Toolbar>
    <v-main class="main-content" :class="{tvview: tvview}">
      <v-container fluid>
        <router-view :key="$route.name"></router-view>
      </v-container>
    </v-main>
    <!-- Footer -->
    <Footer v-if="showFooter"/>
    <SettingsDialog />
    <InactiveModal />
    <MessagesPopup v-if="!tvview"/>
    <div v-if="showTalkChat">
      <v-fab-transition>
        <v-btn
            @click="toggleChat"
            color="primary"
            fab
            bottom
            right
            fixed
            small
            class="v-btn--chat"
        >
            <v-icon>mdi-message</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
    <!-- Chat kartica -->
    <talk-chat style="position: absolute; bottom: 0; right: 5px" v-if="isChatVisible" :current-user="{id: user._id, name: user.name}"></talk-chat>
    <!--Stari chat-->
    <!--<Chat v-if="showChat"></Chat>-->
  </v-app>
</template>
<script>
import Mnavigation from './Common/Mnavigation'
import Snackbar from './Common/Snackbar'
import Toolbar from './Common/Toolbar'
import Footer from './Common/Footer'
import SettingsDialog from './Common/SettingsDialog'
import InactiveModal from './Common/InactiveModal'
import MessagesPopup from './Common/MessagesPopup'
import TalkChat from '@/components/TalkChat/TalkChat.vue'
import {mapGetters} from 'vuex'
// import Chat from './Chat/Chat'
export default {
  data() {
    return {
      tvview: false,
      isChatVisible: false,
    }
  },
  components: {
    Mnavigation,
    Toolbar,
    Footer,
    Snackbar,
    SettingsDialog,
    InactiveModal,
    MessagesPopup,
    TalkChat
    // Chat
  },
  computed: {
    ...mapGetters('auth', ['user']),
    showChat() {
      return this.$route.name === 'dashboard'
    },
    showTalkChat() {
      if (!this.user) return false
      return ['SUPERADMIN', 'DISPATCHER'].includes(this.user.roles[0].role_name)
    },
    showFooter() {
      return ['AvailableView', 'statistics', 'trucks'].includes(this.$route.name)
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.tvview) {
      this.tvview = true
    }
  },
  methods: {
    toggleChat() {
      this.isChatVisible = !this.isChatVisible
    },
  }
}
</script>

<style lang="scss">
.main-content {
  padding-top: 48px !important;

  &.tvview {
    padding-top: 0px !important;
  }
}

.v-app-bar.v-app-bar--fixed {
  z-index: 6;
}

.v-application {

  &.theme--light {
    .v-tour {
      .v-step {
        background: #002984 !important;
      }
    }
  }

  &.theme--dark {
    .v-tour {
      .v-step {
        background: #388e3c !important;
      }
    }
  }
}

.highlight {
  background-color: yellow;
}

.user-info {
  position: absolute;
  height: 20px;
  background-color: rgba(63, 81, 181, 0.8);
  top: -22px;
  right: 0px;
  z-index: 4;
  border-radius: 2px;
  padding: 0 8px;
  color: #fff;
}

.v-btn--chat{
  bottom: 8px !important;
}
</style>
