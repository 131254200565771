import trailers from '../../services/TrailersService'

const state = {
  trailers: [],
  owners: []
}

const getters = {
  trailers (state) {
    return state.trailers
  }
}

const actions = {
  // getTrailers ({commit, dispatch}, payload) {
  //     dispatch('common/setLoading', true, {root: true})
  //     return trailers.getTrailers(payload).then(res => {
  //         commit('setTrailers', res.data.docs)
  //         dispatch('common/setLoading', false, {root: true})
  //         return Promise.resolve(res.data)
  //     }).catch(err => {
  //         dispatch('common/setLoading', false, {root: true})
  //         return Promise.reject(err)
  //     })
  // },
  getAllTrailers ({commit, dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return trailers.getAllTrailers().then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('setTrailers', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllOwners ({commit, dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return trailers.getAllOwners().then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('setOwners', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addTrailer({dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return trailers.addTrailer(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      // commit('addTrailer', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editTrailer ({dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return trailers.editTrailer(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      // commit('editTrailer', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteTrailer ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return trailers.deleteTrailer(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteTrailer', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addHistory ({dispatch}, data) {
    dispatch('common/setLoading', true, {root: true})
    return trailers.addHistory(data).then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setTrailers (state, trailers) {
    state.trailers = trailers
  },
  setOwners (state, owners) {
    state.owners = owners
  },
  addTrailer (state, trailer) {
    const tmpTrailers = state.trailers.slice()
    tmpTrailers.push(trailer)
    state.trailers = tmpTrailers
  },
  editTrailer (state, trailer) {
    let tmpTrailers = state.trailers.slice()
    let index = tmpTrailers.findIndex(el => el._id === trailer._id)
    tmpTrailers.splice(index, 1, trailer)
    console.log(tmpTrailers, 'tmpTrailerstmpTrailers')
    state.trailers = tmpTrailers
  },
  deleteTrailer (state, id) {
    const tmpTrailers = state.trailers.slice()
    tmpTrailers.splice(tmpTrailers.findIndex(el => el._id === id), 1)
    state.trailers = tmpTrailers
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
