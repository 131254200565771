<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click" @contextmenu.prevent="onRightClick">
        {{ trailer.number }}

        <!-- <v-tooltip v-if="!hideNote && trailer.note" right max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="note-icon" v-bind="attrs" v-on="on" right>
              mdi-comment-text-outlines
            </v-icon>
          </template>
          <span style="white-space: pre-wrap">{{
            trailer.note[0] && trailer.note[0].text
          }}</span>
        </v-tooltip> -->
      </div>
    </template>

    <v-card>
      <div style="min-width: 400px; min-height: 200px">
        <trailer-history
          v-if="menu"
          :small="true"
          type="trailer"
          :id="trailer._id || trailer.id"
          :trailerNumber="trailer.number"
        />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import TrailerHistory from './TrailerHistory.vue'

export default {
  props: {
    trailer: {
      type: Object,
      required: true,
    },
    hideNote: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    TrailerHistory,
  },
  data() {
    return {
      menu: false,
    }
  },
  methods: {
    onRightClick() {
      this.$emit('right-click')
    },
  },
}
</script>
