<template>
  <v-footer fixed elevation="6">
    <v-btn
      v-if="trucksLink"
      color="primary"
      class="ml-4 elevation-2"
      style="position: sticky; left: 0"
      dark
      small
      :to="{ name: 'trucks', params: { filter: 'all' } }"
    >
      DRIVER / TRUCK
    </v-btn>
    <v-btn
      v-if="$route.name !== 'AvailableView'"
      color="success"
      class="ml-2 elevation-2"
      style="position: sticky; left: 0"
      dark
      small
      :to="{ name: 'AvailableView' }"
    >
      Available trucks
    </v-btn>
  </v-footer>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    trucksLink() {
      return this.permissions['TRUCK_VIEW'] && this.$route.name !== 'trucks'
    }
  }
}
</script>

<style lang="scss" scoped>
.v-footer {
}
</style>
